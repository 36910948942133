import { lazy } from "react";

const LocationList = lazy(() => import("./locationList"));

const locationRoutes = [
  {
    path: "/location",
    element: <LocationList />
  }
];

export default locationRoutes;
