import { lazy } from "react";

const EventList = lazy(() => import("./eventList"));

const EventRoutes = [
  {
    path: "/event",
    element: <EventList />,
  }
];

export default EventRoutes;
