
const initialState = {
    getTaxList: []  
  }
  
  const UtilityReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_INFO_TYPE_SUCCESS": {             
        return {
          ...state,
          getServiceInfoTypeSuccess: true,
          getServiceInfoTypeList: action.payload.data,
        };
      }
      case "GET_SERVICE_INFO_TYPE_FAILURE": {
        return {
          ...state,
          getServiceInfoTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_INFO_TYPE": {
        return {
          ...state,
          getServiceInfoTypeSuccess: false,
          getServiceInfoTypeFailure: false,
          getServiceInfoTypeList: [],
          errorMessage: false
        };
      }

      case "GET_ORGANIZATION_SUCCESS": {             
        return {
          ...state,
          getOrganizationSuccess: true,
          getOrganizationList: action.payload.data,
        };
      }
      case "GET_ORGANIZATION_FAILURE": {
        return {
          ...state,
          getOrganizationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ORGANIZATION": {
        return {
          ...state,
          getOrganizationSuccess: false,
          getOrganizationFailure: false,
          getOrganizationList: [],
          errorMessage: false
        };
      }

      case "GET_TAX_SUCCESS": {             
        return {
          ...state,
          getTaxSuccess: true,
          getTaxList: action.payload.data,
        };
      }
      case "GET_TAX_FAILURE": {
        return {
          ...state,
          getTaxFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TAX": {
        return {
          ...state,
          getTaxSuccess: false,
          getTaxFailure: false,
          getTaxList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TAX_SUCCESS": {             
        return {
          ...state,
          createTaxSuccess: true,
        };
      }
      case "CREATE_TAX_FAILURE": {
        return {
          ...state,
          createTaxFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TAX": {
        return {
          ...state,
          createTaxSuccess: false,
          createTaxFailure: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TAX_SUCCESS": {             
        return {
          ...state,
          updateTaxSuccess: true,
        };
      }
      case "UPDATE_TAX_FAILURE": {
        return {
          ...state,
          updateTaxFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TAX": {
        return {
          ...state,
          updateTaxSuccess: false,
          updateTaxFailure: false,
          errorMessage: false         
        };
      }


      case "GET_UNIT_SUCCESS": {             
        return {
          ...state,
          getUnitSuccess: true,
          getUnitList: action.payload.data,
        };
      }
      case "GET_UNIT_FAILURE": {
        return {
          ...state,
          getUnitFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_UNIT": {
        return {
          ...state,
          getUnitSuccess: false,
          getUnitFailure: false,
          getUnitList: [],
          errorMessage: false
        };
      }

      case "CREATE_UNIT_SUCCESS": {             
        return {
          ...state,
          createUnitSuccess: action.payload.data,
        };
      }
      case "CREATE_UNIT_FAILURE": {
        return {
          ...state,
          createUnitFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_UNIT": {
        return {
          ...state,
          createUnitSuccess: false,
          createUnitFailure: false,
          errorMessage: false
        };
      }

      case "UPDATE_UNIT_SUCCESS": {             
        return {
          ...state,
          updateUnitSuccess: action.payload.data,
        };
      }
      case "UPDATE_UNIT_FAILURE": {
        return {
          ...state,
          updateUnitFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_UNIT": {
        return {
          ...state,
          updateUnitSuccess: false,
          updateUnitFailure: false,          
          errorMessage: false
        };
      }

      // Location

      case "GET_LOCATION_SUCCESS": {             
        return {
          ...state,
          getLocationSuccess: true,
          getLocationList: action.payload.data,
        };
      }
      case "GET_LOCATION_FAILURE": {
        return {
          ...state,
          getLocationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LOCATION": {
        return {
          ...state,
          getLocationSuccess: false,
          getLocationFailure: false,
          getLocationList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LOCATION_SUCCESS": {             
        return {
          ...state,
          createLocationSuccess: action.payload.data,
        };
      }
      case "CREATE_LOCATION_FAILURE": {
        return {
          ...state,
          createLocationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LOCATION": {
        return {
          ...state,
          createLocationSuccess: false,
          createLocationFailure: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LOCATION_SUCCESS": {             
        return {
          ...state,
          updateLocationSuccess: action.payload.data,
        };
      }
      case "UPDATE_LOCATION_FAILURE": {
        return {
          ...state,
          updateLocationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LOCATION": {
        return {
          ...state,
          updateLocationSuccess: false,
          updateLocationFailure: false,
          errorMessage: false         
        };
      }

      // Warehouse

      case "GET_WAREHOUSE_SUCCESS": {             
        return {
          ...state,
          getWarehouseSuccess: true,
          getWarehouseList: action.payload.data,
        };
      }
      case "GET_WAREHOUSE_FAILURE": {
        return {
          ...state,
          getWarehouseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_WAREHOUSE": {
        return {
          ...state,
          getWarehouseSuccess: false,
          getWarehouseFailure: false,
          getWarehouseList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_WAREHOUSE_SUCCESS": {             
        return {
          ...state,
          createWarehouseSuccess: action.payload.data,
        };
      }
      case "CREATE_WAREHOUSE_FAILURE": {
        return {
          ...state,
          createWarehouseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_WAREHOUSE": {
        return {
          ...state,
          createWarehouseSuccess: false,
          createWarehouseFailure: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_WAREHOUSE_SUCCESS": {             
        return {
          ...state,
          updateWarehouseSuccess: action.payload.data,
        };
      }
      case "UPDATE_WAREHOUSE_FAILURE": {
        return {
          ...state,
          updateWarehouseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_WAREHOUSE": {
        return {
          ...state,
          updateWarehouseSuccess: false,
          updateWarehouseFailure: false,
          errorMessage: false         
        };
      }


      // Price Group

      case "GET_PRICE_GROUP_SUCCESS": {             
        return {
          ...state,
          getPriceGroupSuccess: true,
          getPriceGroupList: action.payload.data,
        };
      }
      case "GET_PRICE_GROUP_FAILURE": {
        return {
          ...state,
          getPriceGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PRICE_GROUP": {
        return {
          ...state,
          getPriceGroupSuccess: false,
          getPriceGroupFailure: false,
          getPriceGroupList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PRICE_GROUP_SUCCESS": {             
        return {
          ...state,
          createPriceGroupSuccess: action.payload.data,
        };
      }
      case "CREATE_PRICE_GROUP_FAILURE": {
        return {
          ...state,
          createPriceGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PRICE_GROUP": {
        return {
          ...state,
          createPriceGroupSuccess: false,
          createPriceGroupFailure: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_PRICE_GROUP_SUCCESS": {             
        return {
          ...state,
          updatePriceGroupSuccess: action.payload.data,
        };
      }
      case "UPDATE_PRICE_GROUP_FAILURE": {
        return {
          ...state,
          updatePriceGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_PRICE_GROUP": {
        return {
          ...state,
          updatePriceGroupSuccess: false,
          updatePriceGroupFailure: false,
          errorMessage: false         
        };
      }

      // Credit Term

      case "GET_CREDIT_TERM_SUCCESS": {             
        return {
          ...state,
          getCreditTermSuccess: true,
          getCreditTermList: action.payload.data,
        };
      }
      case "GET_CREDIT_TERM_FAILURE": {
        return {
          ...state,
          getCreditTermFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CREDIT_TERM": {
        return {
          ...state,
          getCreditTermSuccess: false,
          getCreditTermFailure: false,
          getCreditTermList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CREDIT_TERM_SUCCESS": {             
        return {
          ...state,
          createCreditTermSuccess: action.payload.data,
        };
      }
      case "CREATE_CREDIT_TERM_FAILURE": {
        return {
          ...state,
          createCreditTermFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CREDIT_TERM": {
        return {
          ...state,
          createCreditTermSuccess: false,
          createCreditTermFailure: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CREDIT_TERM_SUCCESS": {             
        return {
          ...state,
          updateCreditTermSuccess: action.payload.data,
        };
      }
      case "UPDATE_CREDIT_TERM_FAILURE": {
        return {
          ...state,
          updateCreditTermFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CREDIT_TERM": {
        return {
          ...state,
          updateCreditTermSuccess: false,
          updateCreditTermFailure: false,
          errorMessage: false         
        };
      }
      
      // Report Type
      case "GET_REPORT_TYPE_SUCCESS": {             
        return {
          ...state,
          getReportTypeSuccess: true,
          getReportTypeList: action.payload.data,
        };
      }
      case "GET_REPORT_TYPE_FAILURE": {
        return {
          ...state,
          getReportTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_TYPE": {
        return {
          ...state,
          getReportTypeSuccess: false,
          getReportTypeFailure: false,
          getReportTypeList: [],
          errorMessage: false
        };
      }

      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default UtilityReducer
  
  