const initialState = {
    homeWorkTypeList: []  
  }
  
  const HomeWorkTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_HOME_WORK_TYPE_SUCCESS": {             
        return {
          ...state,
          getHomeWorkTypeSuccess: true,
          getHomeWorkTypeList: action.payload.data,
        };
      }
      case "GET_HOME_WORK_TYPE_FAILURE": {
        return {
          ...state,
          getHomeWorkTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_HOME_WORK_TYPE": {
        return {
          ...state,
          getHomeWorkTypeSuccess: false,
          getHomeWorkTypeFailure: false,
          getHomeWorkTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_HOME_WORK_TYPE_SUCCESS": {             
        return {
          ...state,
          createHomeWorkTypeSuccess: true,
          createHomeWorkTypeData: action.payload.data,
        };
      }
      case "CREATE_HOME_WORK_TYPE_FAILURE": {
        return {
          ...state,
          createHomeWorkTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_HOME_WORK_TYPE": {
        return {
          ...state,
          createHomeWorkTypeSuccess: false,
          createHomeWorkTypeFailure: false,
          createHomeWorkTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_HOME_WORK_TYPE_SUCCESS": {             
        return {
          ...state,
          updateHomeWorkTypeSuccess: true,
          updateHomeWorkTypeData: action.payload.data,
        };
      }
      case "UPDATE_HOME_WORK_TYPE_FAILURE": {
        return {
          ...state,
          updateHomeWorkTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_HOME_WORK_TYPE": {
        return {
          ...state,
          updateHomeWorkTypeSuccess: false,
          updateHomeWorkTypeFailure: false,
          updateHomeWorkTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default HomeWorkTypeReducer
  
  