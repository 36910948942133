import { lazy } from "react";

const ClassList = lazy(() => import("./classList"));

const classRoutes = [
  {
    path: "/class",
    element: <ClassList />,
  }
];

export default classRoutes;
