import { lazy } from "react";

const UnitList = lazy(() => import("./unitList"));

const UnitRoutes = [
  {
    path: "/unit",
    element: <UnitList />,
  }
];

export default UnitRoutes;
