const initialState = {
    courseList: []  
  }
  
  const CourseReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_COURSE_SUCCESS": {             
        return {
          ...state,
          getCourseSuccess: true,
          getCourseList: action.payload.data,
        };
      }
      case "GET_COURSE_FAILURE": {
        return {
          ...state,
          getCourseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_COURSE": {
        return {
          ...state,
          getCourseSuccess: false,
          getCourseFailure: false,
          getCourseList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_COURSE_SUCCESS": {             
        return {
          ...state,
          createCourseSuccess: true,
          createCourseData: action.payload.data,
        };
      }
      case "CREATE_COURSE_FAILURE": {
        return {
          ...state,
          createCourseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_COURSE": {
        return {
          ...state,
          createCourseSuccess: false,
          createCourseFailure: false,
          createCourseData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_COURSE_SUCCESS": {             
        return {
          ...state,
          updateCourseSuccess: true,
          updateCourseData: action.payload.data,
        };
      }
      case "UPDATE_COURSE_FAILURE": {
        return {
          ...state,
          updateCourseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_COURSE": {
        return {
          ...state,
          updateCourseSuccess: false,
          updateCourseFailure: false,
          updateCourseData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CourseReducer
  
  