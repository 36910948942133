import { lazy } from "react";

const EmployeeAttendanceList = lazy(() => import("./employeeAttendanceList"));

const employeeAttendanceRoutes = [
  {
    path: "/employee-attendance",
    element: <EmployeeAttendanceList />,
  }
];

export default employeeAttendanceRoutes;
