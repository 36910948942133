import { lazy } from "react";

const SyllabusList = lazy(() => import("./syllabusList"));

const SyllabusRoutes = [
  {
    path: "/syllabus",
    element: <SyllabusList />,
  }
];

export default SyllabusRoutes;
