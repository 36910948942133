const initialState = {
    enrollmentDetailsList: []  
  }
  
  const EnrollmentDetailsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ENROLLMENT_DETAILS_SUCCESS": {             
        return {
          ...state,
          getEnrollmentDetailsSuccess: true,
          getEnrollmentDetailsList: action.payload.data,
        };
      }
      case "GET_ENROLLMENT_DETAILS_FAILURE": {
        return {
          ...state,
          getEnrollmentDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ENROLLMENT_DETAILS": {
        return {
          ...state,
          getEnrollmentDetailsSuccess: false,
          getEnrollmentDetailsFailure: false,
          getEnrollmentDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ENROLLMENT_DETAILS_SUCCESS": {             
        return {
          ...state,
          createEnrollmentDetailsSuccess: true,
          createEnrollmentDetailsData: action.payload.data,
        };
      }
      case "CREATE_ENROLLMENT_DETAILS_FAILURE": {
        return {
          ...state,
          createEnrollmentDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ENROLLMENT_DETAILS": {
        return {
          ...state,
          createEnrollmentDetailsSuccess: false,
          createEnrollmentDetailsFailure: false,
          createEnrollmentDetailsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ENROLLMENT_DETAILS_SUCCESS": {             
        return {
          ...state,
          updateEnrollmentDetailsSuccess: true,
          updateEnrollmentDetailsData: action.payload.data,
        };
      }
      case "UPDATE_ENROLLMENT_DETAILS_FAILURE": {
        return {
          ...state,
          updateEnrollmentDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ENROLLMENT_DETAILS": {
        return {
          ...state,
          updateEnrollmentDetailsSuccess: false,
          updateEnrollmentDetailsFailure: false,
          updateEnrollmentDetailsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EnrollmentDetailsReducer
  
  