import { lazy } from "react";

const QualificationList = lazy(() => import("./qualificationList"));

const qualificationRoutes = [
  {
    path: "/qualification",
    element: <QualificationList />,
  }
];

export default qualificationRoutes;
