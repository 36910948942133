import "../fake-db";
import "../styles/app/app.scss";
import { AllPages } from './routes/routes'
import Auth from "./auth/Auth";
import React from "react";
import routes from "./routes";
import { RouterProvider, useRoutes } from "react-router-dom";
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { Provider } from "react-redux";
import { store } from "app/store";

import { NotificationContainer } from "react-notifications";
function App() {
  
  const all_pages = useRoutes(AllPages())

  return (
    <Provider store={store}>
      <AuthProvider >
        {all_pages}        
      </AuthProvider>
      <NotificationContainer />
    </Provider>    
  )


  return (
    <Auth>
      <RouterProvider router={routes} />
      <NotificationContainer />
    </Auth>
  );
}

export default App;
