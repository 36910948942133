const initialState = {
    inventoryAdjustmentList: []  
  }
  
  const InventoryAdjustmenReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_INVENTORY_ADJUSTMENT_SUCCESS": {             
        return {
          ...state,
          getInventoryAdjustmentSuccess: true,
          getInventoryAdjustmentList: action.payload.data,
        };
      }
      case "GET_INVENTORY_ADJUSTMENT_FAILURE": {
        return {
          ...state,
          getInventoryAdjustmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_INVENTORY_ADJUSTMENT": {
        return {
          ...state,
          getInventoryAdjustmentSuccess: false,
          getInventoryAdjustmentFailure: false,
          getInventoryAdjustmentList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_INVENTORY_ADJUSTMENT_SUCCESS": {             
        return {
          ...state,
          createInventoryAdjustmentSuccess: true,
          createInventoryAdjustmentData: action.payload.data,
        };
      }
      case "CREATE_INVENTORY_ADJUSTMENT_FAILURE": {
        return {
          ...state,
          createInventoryAdjustmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_INVENTORY_ADJUSTMENT": {
        return {
          ...state,
          createInventoryAdjustmentSuccess: false,
          createInventoryAdjustmentFailure: false,
          createInventoryAdjustmentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_INVENTORY_ADJUSTMENT_SUCCESS": {             
        return {
          ...state,
          updateInventoryAdjustmentSuccess: true,
          updateInventoryAdjustmentData: action.payload.data,
        };
      }
      case "UPDATE_INVENTORY_ADJUSTMENT_FAILURE": {
        return {
          ...state,
          updateInventoryAdjustmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_INVENTORY_ADJUSTMENT": {
        return {
          ...state,
          updateInventoryAdjustmentSuccess: false,
          updateInventoryAdjustmentFailure: false,
          updateInventoryAdjustmentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default InventoryAdjustmenReducer
  
  