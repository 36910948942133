const initialState = {
    employeeAttendanceList: []  
  }
  
  const EmployeeAttendanceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_EMPLOYEE_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          getEmployeeAttendanceSuccess: true,
          getEmployeeAttendanceList: action.payload.data,
        };
      } 
      case "GET_EMPLOYEE_ATTENDANCE_FAILURE": {
        return {
          ...state,
          getEmployeeAttendanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EMPLOYEE_ATTENDANCE": {
        return {
          ...state,
          getEmployeeAttendanceSuccess: false,
          getEmployeeAttendanceFailure: false,
          getEmployeeAttendanceList: [],
          errorMessage: false
        };
      }

      case "GET_EMPLOYEE_ATTENDANCE_DETAILS_SUCCESS": {             
        return {
          ...state,
          getEmployeeAttendanceDetailsSuccess: true,
          getEmployeeAttendanceDetailsList: action.payload.data,
        };
      } 

      case "GET_EMPLOYEE_ATTENDANCE_DETAILS_FAILURE": {
        return {
          ...state,
          getEmployeeAttendanceDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }

      case "RESET_GET_EMPLOYEE_DETAILS_ATTENDANCE": {
        return {
          ...state,
          getEmployeeAttendanceDetailsSuccess: false,
          getEmployeeAttendanceDetailsFailure: false,
          getEmployeeAttendanceDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_EMPLOYEE_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          createEmployeeAttendanceSuccess: true,
          createEmployeeAttendanceData: action.payload.data,
        };
      }
      case "CREATE_EMPLOYEE_ATTENDANCE_FAILURE": {
        return {
          ...state,
          createEmployeeAttendanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_EMPLOYEE_ATTENDANCE": {
        return {
          ...state,
          createEmployeeAttendanceSuccess: false,
          createEmployeeAttendanceFailure: false,
          createEmployeeAttendanceData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_EMPLOYEE_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          updateEmployeeAttendanceSuccess: true,
          updateEmployeeAttendanceData: action.payload.data,
        };
      }
      case "UPDATE_EMPLOYEE_ATTENDANCE_FAILURE": {
        return {
          ...state,
          updateEmployeeAttendanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_EMPLOYEE_ATTENDANCE": {
        return {
          ...state,
          updateEmployeeAttendanceSuccess: false,
          updateEmployeeAttendanceFailure: false,
          updateEmployeeAttendanceData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EmployeeAttendanceReducer
  
  