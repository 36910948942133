import { lazy } from "react";

const ExamList = lazy(() => import("./examList"));

const examRoutes = [
  {
    path: "/exam",
    element: <ExamList />,
  }
];

export default examRoutes;
