const initialState = {
    creditNotesList: []  
  }
  
  const CreditReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CREDIT_NOTES_SUCCESS": {             
        return {
          ...state,
          getCreditNotesSuccess: true,
          getCreditNotesList: action.payload.data,
        };
      }
      case "GET_CREDIT_NOTES_FAILURE": {
        return {
          ...state,
          getCreditNotesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CREDIT_NOTES": {
        return {
          ...state,
          getCreditNotesSuccess: false,
          getCreditNotesFailure: false,
          getCreditNotesList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CREDIT_NOTES_SUCCESS": {             
        return {
          ...state,
          createCreditNotesSuccess: true,
          createCreditNotesData: action.payload.data,
        };
      }
      case "CREATE_CREDIT_NOTES_FAILURE": {
        return {
          ...state,
          createCreditNotesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CREDIT_NOTES": {
        return {
          ...state,
          createCreditNotesSuccess: false,
          createCreditNotesFailure: false,
          createCreditNotesData: false,
          errorMessage: false         
        };
      }
      case "UPDATE_CREDIT_NOTES_SUCCESS": {             
        return {
          ...state,
          updateCreditNotesSuccess: true,
          updateCreditNotesData: action.payload.data,
        };
      }
      case "UPDATE_CREDIT_NOTES_FAILURE": {
        return {
          ...state,
          updateCreditNotesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CREDIT_NOTES": {
        return {
          ...state,
          updateCreditNotesSuccess: false,
          updateCreditNotesFailure: false,
          updateCreditNotesData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CreditReducer
  
  