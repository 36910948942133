const initialState = {
    knowledgeBookTypeList: []  
  }
 
  const KnowledgeBookTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_KNOWLEDGE_BOOK_TYPE_SUCCESS": {            
        return {
          ...state,
          getKnowledgeBookTypeSuccess: true,
          getKnowledgeBookTypeList: action.payload.data,
        };
      }
      case "GET_KNOWLEDGE_BOOK_TYPE_FAILURE": {
        return {
          ...state,
          getKnowledgeBookTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_KNOWLEDGE_BOOK_TYPE": {
        return {
          ...state,
          getKnowledgeBookTypeSuccess: false,
          getKnowledgeBookTypeFailure: false,
          getKnowledgeBookTypeList: [],
          errorMessage: false
        };
      }
     
      case "CREATE_KNOWLEDGE_BOOK_TYPE_SUCCESS": {            
        return {
          ...state,
          createKnowledgeBookTypeSuccess: true,
          createKnowledgeBookTypeData: action.payload.data,
        };
      }
      case "CREATE_KNOWLEDGE_BOOK_TYPE_FAILURE": {
        return {
          ...state,
          createKnowledgeBookTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_KNOWLEDGE_BOOK_TYPE": {
        return {
          ...state,
          createKnowledgeBookTypeSuccess: false,
          createKnowledgeBookTypeFailure: false,
          createKnowledgeBookTypeData: false,
          errorMessage: false        
        };
      }
 
      case "UPDATE_KNOWLEDGE_BOOK_TYPE_SUCCESS": {            
        return {
          ...state,
          updateKnowledgeBookTypeSuccess: true,
          updateKnowledgeBookTypeData: action.payload.data,
        };
      }
      case "UPDATE_KNOWLEDGE_BOOK_TYPE_FAILURE": {
        return {
          ...state,
          updateKnowledgeBookTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_KNOWLEDGE_BOOK_TYPE": {
        return {
          ...state,
          updateKnowledgeBookTypeSuccess: false,
          updateKnowledgeBookTypeFailure: false,
          updateKnowledgeBookTypeData: false,
          errorMessage: false        
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
 
  export default KnowledgeBookTypeReducer