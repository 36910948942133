const initialState = {
    designationList: []  
  }
  
  const DesignationReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DESIGNATION_SUCCESS": {             
        return {
          ...state,
          getDesignationSuccess: true,
          getDesignationList: action.payload.data,
        };
      }
      case "GET_DESIGNATION_FAILURE": {
        return {
          ...state,
          getDesignationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DESIGNATION": {
        return {
          ...state,
          getDesignationSuccess: false,
          getDesignationFailure: false,
          getDesignationList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_DESIGNATION_SUCCESS": {             
        return {
          ...state,
          createDesignationSuccess: true,
          createDesignationData: action.payload.data,
        };
      }
      case "CREATE_DESIGNATION_FAILURE": {
        return {
          ...state,
          createDesignationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DESIGNATION": {
        return {
          ...state,
          createDesignationSuccess: false,
          createDesignationFailure: false,
          createDesignationData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DESIGNATION_SUCCESS": {             
        return {
          ...state,
          updateDesignationSuccess: true,
          updateDesignationData: action.payload.data,
        };
      }
      case "UPDATE_DESIGNATION_FAILURE": {
        return {
          ...state,
          updateDesignationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DESIGNATION": {
        return {
          ...state,
          updateDesignationSuccess: false,
          updateDesignationFailure: false,
          updateDesignationData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DesignationReducer
  
  