const initialState = {
    leaveBalanceList: []  
  }
  
  const LeaveBalanceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_LEAVE_BALANCE_SUCCESS": {             
        return {
          ...state,
          getLeaveBalanceSuccess: true,
          getLeaveBalanceList: action.payload.data,
        };
      }
      case "GET_LEAVE_BALANCE_FAILURE": {
        return {
          ...state,
          getLeaveBalanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LEAVE_BALANCE": {
        return {
          ...state,
          getLeaveBalanceSuccess: false,
          getLeaveBalanceFailure: false,
          getLeaveBalanceList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LEAVE_BALANCE_SUCCESS": {             
        return {
          ...state,
          createLeaveBalanceSuccess: true,
          createLeaveBalanceData: action.payload.data,
        };
      }
      case "CREATE_LEAVE_BALANCE_FAILURE": {
        return {
          ...state,
          createLeaveBalanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LEAVE_BALANCE": {
        return {
          ...state,
          createLeaveBalanceSuccess: false,
          createLeaveBalanceFailure: false,
          createLeaveBalanceData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LEAVE_BALANCE_SUCCESS": {             
        return {
          ...state,
          updateLeaveBalanceSuccess: true,
          updateLeaveBalanceData: action.payload.data,
        };
      }
      case "UPDATE_LEAVE_BALANCE_FAILURE": {
        return {
          ...state,
          updateLeaveBalanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LEAVE_BALANCE": {
        return {
          ...state,
          updateLeaveBalanceSuccess: false,
          updateLeaveBalanceFailure: false,
          updateLeaveBalanceData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default LeaveBalanceReducer
  
  