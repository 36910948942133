import { lazy } from "react";

const AdmissionList = lazy(() => import("./admissionList"));

const admissionRoutes = [
  {
    path: "/admission",
    element: <AdmissionList />,
  }
];

export default admissionRoutes;
