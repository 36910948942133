const initialState = {
    studentStatusList: []  
  }
  
  const StudentStatusReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_STUDENT_STATUS_SUCCESS": {             
        return {
          ...state,
          getStudentStatusSuccess: true,
          getStudentStatusList: action.payload.data,
        };
      }
      case "GET_STUDENT_STATUS_FAILURE": {
        return {
          ...state,
          getStudentStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_STATUS": {
        return {
          ...state,
          getStudentStatusSuccess: false,
          getStudentStatusFailure: false,
          getStudentStatusList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_STUDENT_STATUS_SUCCESS": {             
        return {
          ...state,
          createStudentStatusSuccess: true,
          createStudentStatusData: action.payload.data,
        };
      }
      case "CREATE_STUDENT_STATUS_FAILURE": {
        return {
          ...state,
          createStudentStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_STUDENT_STATUS": {
        return {
          ...state,
          createStudentStatusSuccess: false,
          createStudentStatusFailure: false,
          createStudentStatusData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_STUDENT_STATUS_SUCCESS": {             
        return {
          ...state,
          updateStudentStatusSuccess: true,
          updateStudentStatusData: action.payload.data,
        };
      }
      case "UPDATE_STUDENT_STATUS_FAILURE": {
        return {
          ...state,
          updateStudentStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_STUDENT_STATUS": {
        return {
          ...state,
          updateStudentStatusSuccess: false,
          updateStudentStatusFailure: false,
          updateStudentStatusData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default StudentStatusReducer
  
  