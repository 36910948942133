import { lazy } from "react";

const SectionList = lazy(() => import("./sectionList"));

const sectionRoutes = [
  {
    path: "/section",
    element: <SectionList />,
  }
];

export default sectionRoutes;
