const initialState = {
    goodsReceivedList: []  
  }
  
  const GoodsReceivedReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_GOODSRECEIVED_SUCCESS": {             
        return {
          ...state,
          getGoodsReceivedSuccess: true,
          getGoodsReceivedList: action.payload.data,
        };
      }
      case "GET_GOODSRECEIVED_FAILURE": {
        return {
          ...state,
          getGoodsReceivedFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_GOODSRECEIVED": {
        return {
          ...state,
          getGoodsReceivedSuccess: false,
          getGoodsReceivedFailure: false,
          getGoodsReceivedList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_GOODSRECEIVED_SUCCESS": {             
        return {
          ...state,
          createGoodsReceivedSuccess: true,
          createGoodsReceivedData: action.payload.data,
        };
      }
      case "CREATE_GOODSRECEIVED_FAILURE": {
        return {
          ...state,
          createGoodsReceivedFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_GOODSRECEIVED": {
        return {
          ...state,
          createGoodsReceivedSuccess: false,
          createGoodsReceivedFailure: false,
          createGoodsReceivedData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_GOODSRECEIVED_SUCCESS": {             
        return {
          ...state,
          updateGoodsReceivedSuccess: true,
          updateGoodsReceivedData: action.payload.data,
        };
      }
      case "UPDATE_GOODSRECEIVED_FAILURE": {
        return {
          ...state,
          updateGoodsReceivedFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_GOODSRECEIVED": {
        return {
          ...state,
          updateGoodsReceivedSuccess: false,
          updateGoodsReceivedFailure: false,
          updateGoodsReceivedData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default GoodsReceivedReducer
  
  