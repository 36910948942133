const initialState = {
    leaveMetaList: []  
  }
  
  const LeaveMetaReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_LEAVE_META_SUCCESS": {             
        return {
          ...state,
          getLeaveMetaSuccess: true,
          getLeaveMetaList: action.payload.data,
        };
      }
      case "GET_LEAVE_META_FAILURE": {
        return {
          ...state,
          getLeaveMetaFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LEAVE_META": {
        return {
          ...state,
          getLeaveMetaSuccess: false,
          getLeaveMetaFailure: false,
          getLeaveMetaList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LEAVE_META_SUCCESS": {             
        return {
          ...state,
          createLeaveMetaSuccess: true,
          createLeaveMetaData: action.payload.data,
        };
      }
      case "CREATE_LEAVE_META_FAILURE": {
        return {
          ...state,
          createLeaveMetaFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LEAVE_META": {
        return {
          ...state,
          createLeaveMetaSuccess: false,
          createLeaveMetaFailure: false,
          createLeaveMetaData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LEAVE_META_SUCCESS": {             
        return {
          ...state,
          updateLeaveMetaSuccess: true,
          updateLeaveMetaData: action.payload.data,
        };
      }
      case "UPDATE_LEAVE_META_FAILURE": {
        return {
          ...state,
          updateLeaveMetaFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LEAVE_META": {
        return {
          ...state,
          updateLeaveMetaSuccess: false,
          updateLeaveMetaFailure: false,
          updateLeaveMetaData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default LeaveMetaReducer
  
  