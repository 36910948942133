import { lazy } from "react";

const PurchaseList = lazy(() => import("./purchaseList"));

const purchaseRoutes = [
  {
    path: "/purchase",
    element: <PurchaseList />,
  }
];

export default purchaseRoutes;
