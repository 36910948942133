const initialState = {
    bloodGroupList: []  
  }
  
  const BloodGroupReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_BLOOD_GROUP_SUCCESS": {             
        return {
          ...state,
          getBloodGroupSuccess: true,
          getBloodGroupList: action.payload.data,
        };
      }
      case "GET_BLOOD_GROUP_FAILURE": {
        return {
          ...state,
          getBloodGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_BLOOD_GROUP": {
        return {
          ...state,
          getBloodGroupSuccess: false,
          getBloodGroupFailure: false,
          getBloodGroupList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_BLOOD_GROUP_SUCCESS": {             
        return {
          ...state,
          createBloodGroupSuccess: true,
          createBloodGroupData: action.payload.data,
        };
      }
      case "CREATE_BLOOD_GROUP_FAILURE": {
        return {
          ...state,
          createBloodGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_BLOOD_GROUP": {
        return {
          ...state,
          createBloodGroupSuccess: false,
          createBloodGroupFailure: false,
          createBloodGroupData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_BLOOD_GROUP_SUCCESS": {             
        return {
          ...state,
          updateBloodGroupSuccess: true,
          updateBloodGroupData: action.payload.data,
        };
      }
      case "UPDATE_BLOOD_GROUP_FAILURE": {
        return {
          ...state,
          updateBloodGroupFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_BLOOD_GROUP": {
        return {
          ...state,
          updateBloodGroupSuccess: false,
          updateBloodGroupFailure: false,
          updateBloodGroupData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default BloodGroupReducer