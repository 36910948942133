const initialState = {
    gradeList: []  
  }
  
  const GradeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_GRADE_SUCCESS": {             
        return {
          ...state,
          getGradeSuccess: true,
          getGradeList: action.payload.data,
        };
      }
      case "GET_GRADE_FAILURE": {
        return {
          ...state,
          getGradeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_GRADE": {
        return {
          ...state,
          getGradeSuccess: false,
          getGradeFailure: false,
          getGradeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_GRADE_SUCCESS": {             
        return {
          ...state,
          createGradeSuccess: true,
          createGradeData: action.payload.data,
        };
      }
      case "CREATE_GRADE_FAILURE": {
        return {
          ...state,
          createGradeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_GRADE": {
        return {
          ...state,
          createGradeSuccess: false,
          createGradeFailure: false,
          createGradeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_GRADE_SUCCESS": {             
        return {
          ...state,
          updateGradeSuccess: true,
          updateGradeData: action.payload.data,
        };
      }
      case "UPDATE_GRADE_FAILURE": {
        return {
          ...state,
          updateGradeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_GRADE": {
        return {
          ...state,
          updateGradeSuccess: false,
          updateGradeFailure: false,
          updateGradeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default GradeReducer
  
  