const initialState = {
    DocumentTypeList: []  
  }
  
  const DocumentTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DOCUMENT_TYPE_SUCCESS": {             
        return {
          ...state,
          getDocumentTypeSuccess: true,
          getDocumentTypeList: action.payload.data,
        };
      }
      case "GET_DOCUMENT_TYPE_FAILURE": {
        return {
          ...state,
          getDocumentTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DOCUMENT_TYPE": {
        return {
          ...state,
          getDocumentTypeSuccess: false,
          getDocumentTypeFailure: false,
          getDocumentTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_USER_SUCCESS": {             
        return {
          ...state,
          createUserSuccess: true,
          createUserData: action.payload.data,
        };
      }
      case "CREATE_USER_FAILURE": {
        return {
          ...state,
          createUserFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_USER": {
        return {
          ...state,
          createUserSuccess: false,
          createUserFailure: false,
          createUserData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_USER_SUCCESS": {             
        return {
          ...state,
          updateUserSuccess: true,
          updateUserData: action.payload.data,
        };
      }
      case "UPDATE_USER_FAILURE": {
        return {
          ...state,
          updateUserFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_USER": {
        return {
          ...state,
          updateUserSuccess: false,
          updateUserFailure: false,
          updateUserData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DocumentTypeReducer
  
  