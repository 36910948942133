const initialState = {
    schoolDashboardList: []  
  }
  
  const SchoolDashboardReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SCHOOL_DASHBOARD_SUCCESS": {             
        return {
          ...state,
          getSchoolDashboardSuccess: true,
          getSchoolDashboardList: action.payload.data,
        };
      }
      case "GET_SCHOOL_DASHBOARD_FAILURE": {
        return {
          ...state,
          getSchoolDashboardFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SCHOOL_DASHBOARD": {
        return {
          ...state,
          getSchoolDashboardSuccess: false,
          getSchoolDashboardFailure: false,
          getSchoolDashboardList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SCHOOL_DASHBOARD_SUCCESS": {             
        return {
          ...state,
          createSchoolDashboardSuccess: true,
          createSchoolDashboardData: action.payload.data,
        };
      }
      case "CREATE_SCHOOL_DASHBOARD_FAILURE": {
        return {
          ...state,
          createSchoolDashboardFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SCHOOL_DASHBOARD": {
        return {
          ...state,
          createSchoolDashboardSuccess: false,
          createSchoolDashboardFailure: false,
          createSchoolDashboardData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SCHOOL_DASHBOARD_SUCCESS": {             
        return {
          ...state,
          updateSchoolDashboardSuccess: true,
          updateSchoolDashboardData: action.payload.data,
        };
      }
      case "UPDATE_SCHOOL_DASHBOARD_FAILURE": {
        return {
          ...state,
          updateSchoolDashboardFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SCHOOL_DASHBOARD": {
        return {
          ...state,
          updateSchoolDashboardSuccess: false,
          updateSchoolDashboardFailure: false,
          updateSchoolDashboardData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SchoolDashboardReducer
  
  