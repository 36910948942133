import { baseURL, ports } from "app/utils/constant";
import axios from "axios";

function getBaseUrl(url, type) {
    //  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

return url;
  if (url.includes("document-management")) {    
    axios.defaults.baseURL = baseURL+ports.image;    
  }

   if (url === "login") {    
    axios.defaults.baseURL = baseURL+ports.access;    
  }
  else if (url.includes("access-management")) {
    axios.defaults.baseURL = baseURL+ports.access;
  }
  else if (url.includes("home-management")) {
    axios.defaults.baseURL = baseURL+ports.home;    
  }
  else if (url.includes("report-management")) {    
    axios.defaults.baseURL = baseURL+ports.report;    
  }
  else if (url.includes("employee-management")) {    
    axios.defaults.baseURL = baseURL+ports.employee;    
  }
  else if (url.includes("unit-management")) {    
    axios.defaults.baseURL = baseURL+ports.unit;    
  }
  else if (url.includes("document-management")) {    
    axios.defaults.baseURL = baseURL+ports.image;    
  }
  else if (url.includes("inventory-management")) {    
    axios.defaults.baseURL = baseURL+ports.inventory;    
  }
  else {
    axios.defaults.baseURL = baseURL+ports.payroll;    
  } 
  return url;
}

export function getAPIUrl(url) {
  let baseURLVal = baseURL+ports.payroll;
  baseURLVal = baseURLVal+url
  return baseURLVal;
}

const getSession = (sessionName) => {
  return sessionName ? window.localStorage.getItem(sessionName) : false
}

export function returnApiCallPromise(method, url, object = null, config = null) {  
  const unitData = getSession('unitId');
  global.unitCode = getSession('unitCode');
  global.unitId = unitData
  global.roleId = global.UserInfo?.roleId || 0
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  axios.defaults.headers['unit_id'] = global.unitId;
  // axios.defaults.headers['unit_id'] = 1;

  axios.defaults.headers['type'] = "web";
  axios.defaults.headers['zoneid'] = "1";
  axios.defaults.headers['user_id'] = global.userId ;
  axios.defaults.headers['role_id'] = global.roleId ;
  axios.defaults.headers['unit_code'] = global.unitCode
  axios.defaults.headers['product'] = "sp";
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  //axios.create({withCredentials : true});
  // axios.defaults.headers['org_id'] = global.OrgID ? global.OrgID : 0;
  // axios.defaults.headers['branchid'] = global.branchId ? global.branchId : 0;
  // axios.defaults.headers['usercode'] = global.usercode;  
  // axios.defaults.headers['counterid'] = global.counterId ? global.counterId : 0;
  // eslint-disable-next-line default-case
  switch (method) {
    case 'GET':      
      return axios.get(getBaseUrl(url), object, config);
    case 'POST':      
      return axios.post(getBaseUrl(url), object, config);
    case 'PUT':
      return axios.put(getBaseUrl(url), object, config);
    case 'DELETE':
      return axios.delete(getBaseUrl(url), object, config);
    case 'FORMPUT':
      const configs = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Length': JSON.stringify(object).length,
          'content-type': 'multipart/form-data'
        }
      }
      return axios.put(getBaseUrl(url), object, configs);
    case 'FORMPOST':
      const postConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Length': JSON.stringify(object).length,
          'content-type': 'multipart/form-data'
        }
      }
      return axios.post(getBaseUrl(url), object, postConfig);
  }
}
