
const initialState = {
  employeeList: []  
}

const EmployeeReducer = function (state = initialState, action) {  
  switch (action.type) {
    case "GET_EMPLOYEE_SUCCESS": {               
      return {
        ...state,
        getEmployeeSuccess: true,
        getEmployeeList: action.payload.data,
      };
    }
    case "GET_EMPLOYEE_FAILURE": {
      return {
        ...state,
        getEmployeeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_EMPLOYEE": {
      return {
        ...state,
        getEmployeeSuccess: false,
        getEmployeeFailure: false,
        getEmployeeList: [],
        errorMessage: false
      };
    }

    case "GET_EMPLOYEE_PROMOTION_SUCCESS": {               
      return {
        ...state,
        getEmployeePromotionSuccess: true,
        getEmployeePromotionList: action.payload.data,
      };
    }
    case "GET_EMPLOYEE_PROMOTION_FAILURE": {
      return {
        ...state,
        getEmployeePromotionFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_EMPLOYEE_PROMOTION": {
      return {
        ...state,
        getEmployeePromotionSuccess: false,
        getEmployeePromotionFailure: false,
        getEmployeePromotionList: [],
        errorMessage: false
      };
    }

    case "GET_EMPLOYEE_REPORT_SUCCESS": {               
      return {
        ...state,
        getEmployeeReportSuccess: true,
        getEmployeeReportList: action.payload.data,
      };
    }
    case "GET_EMPLOYEE_REPORT_FAILURE": {
      return {
        ...state,
        getEmployeeReportFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_EMPLOYEE_REPORT": {
      return {
        ...state,
        getEmployeeReportSuccess: false,
        getEmployeeReportFailure: false,
        getEmployeeReportList: [],
        errorMessage: false
      };
    }

    case "CREATE_EMPLOYEE_SUCCESS": {             
      return {
        ...state,
        createEmployeeSuccess: true,
        createEmployeeData: action.payload.data,
      };
    }
    case "CREATE_EMPLOYEE_FAILURE": {
      return {
        ...state,
        createEmployeeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_EMPLOYEE": {
      return {
        ...state,
        createEmployeeSuccess: false,
        createEmployeeFailure: false,
        createEmployeeData: [],
        errorMessage: false
      };
    }

    case "CREATE_EMPLOYEE_PROMOTION_SUCCESS": {             
      return {
        ...state,
        createEmployeePromotionSuccess: true,
        createEmployeePromotionData: action.payload.data,
      };
    }
    case "CREATE_EMPLOYEE_PROMOTION_FAILURE": {
      return {
        ...state,
        createEmployeePromotionFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_EMPLOYEE_PROMOTION": {
      return {
        ...state,
        createEmployeePromotionSuccess: false,
        createEmployeePromotionFailure: false,
        createEmployeePromotionData: [],
        errorMessage: false
      };
    }


    case "UPDATE_EMPLOYEE_SUCCESS": {             
      return {
        ...state,
        updateEmployeeSuccess: true,
        updateEmployeeData: action.payload.data,
      };
    }
    case "UPDATE_EMPLOYEE_FAILURE": {
      return {
        ...state,
        updateEmployeeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_EMPLOYEE": {
      return {
        ...state,
        updateEmployeeSuccess: false,
        updateEmployeeFailure: false,
        updateEmployeeData: [],
        errorMessage: false
      };
    } 

    case "UPDATE_EMPLOYEE_PROMOTION_SUCCESS": {             
      return {
        ...state,
        updateEmployeePromotionSuccess: true,
        updateEmployeePromotionData: action.payload.data,
      };
    }
    case "UPDATE_EMPLOYEE_PROMOTION_FAILURE": {
      return {
        ...state,
        updateEmployeePromotionFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_EMPLOYEE_PROMOTION": {
      return {
        ...state,
        updateEmployeePromotionSuccess: false,
        updateEmployeePromotionFailure: false,
        updateEmployeePromotionData: [],
        errorMessage: false
      };
    }

    //DELETE JOB HISTORY
    case "DELETE_EMPLOYEE_JOB_HISTORY_SUCCESS": {             
      return {
        ...state,
        deleteEmployeeJobHistorySuccess: true,
      };
    }
    case "DELETE_EMPLOYEE_JOB_HISTORY_FAILURE": {
      return {
        ...state,
        deleteEmployeeJobHistoryFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_DELETE_EMPLOYEE_JOB_HISTORY": {
      return {
        ...state,
        deleteEmployeeJobHistorySuccess: false,
        deleteEmployeeJobHistoryFailure: false,
        errorMessage: false         
      };
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default EmployeeReducer

