const initialState = {
    locationList: []  
  }
  
  const LocationReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_LOCATION_SUCCESS": {             
        return {
          ...state,
          getLocationSuccess: true,
          getLocationList: action.payload.data,
        };
      }
      case "GET_LOCATION_FAILURE": {
        return {
          ...state,
          getLocationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LOCATION": {
        return {
          ...state,
          getLocationSuccess: false,
          getLocationFailure: false,
          getLocationList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LOCATION_SUCCESS": {             
        return {
          ...state,
          createLocationSuccess: true,
          createLocationData: action.payload.data,
        };
      }
      case "CREATE_LOCATION_FAILURE": {
        return {
          ...state,
          createLocationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LOCATION": {
        return {
          ...state,
          createLocationSuccess: false,
          createLocationFailure: false,
          createLocationData: false,
        };
      }
      case "CREATE_USER_SUCCESS": {             
        return {
          ...state,
          createUserSuccess: true,
          createUserData: action.payload.data,
        };
      }
      case "CREATE_USER_FAILURE": {
        return {
          ...state,
          createUserFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_USER": {
        return {
          ...state,
          createUserSuccess: false,
          createUserFailure: false,
          createUserData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LOCATION_SUCCESS": {             
        return {
          ...state,
          updateLocationSuccess: true,
          updateLocationData: action.payload.data,
        };
      }
      case "UPDATE_LOCATION_FAILURE": {
        return {
          ...state,
          updateLocationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LOCATION": {
        return {
          ...state,
          updateLocationSuccess: false,
          updateLocationFailure: false,
          updateLocationData: false,
        };
      }
      case "UPDATE_USER_SUCCESS": {             
        return {
          ...state,
          updateUserSuccess: true,
          updateUserData: action.payload.data,
        };
      }
      case "UPDATE_USER_FAILURE": {
        return {
          ...state,
          updateUserFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_USER": {
        return {
          ...state,
          updateUserSuccess: false,
          updateUserFailure: false,
          updateUserData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default LocationReducer
  
  
