import { lazy } from "react";

const ExamTypeList = lazy(() => import("./examTypeList"));

const examTypeRoutes = [
  {
    path: "/exam-type",
    element: <ExamTypeList />,
  }
];

export default examTypeRoutes;
