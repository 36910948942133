const initialState = {
    taxCodeList: []  
  }
  
  const TaxCodeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TAXCODE_SUCCESS": {             
        return {
          ...state,
          getTaxCodeSuccess: true,
          getTaxCodeList: action.payload.data,
        };
      }
      case "GET_TAXCODE_FAILURE": {
        return {
          ...state,
          getTaxCodeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TAXCODE": {
        return {
          ...state,
          getTaxCodeSuccess: false,
          getTaxCodeFailure: false,
          getTaxCodeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TAXCODE_SUCCESS": {             
        return {
          ...state,
          createTaxCodeSuccess: true,
          createTaxCodeData: action.payload.data,
        };
      }
      case "CREATE_TAXCODE_FAILURE": {
        return {
          ...state,
          createTaxCodeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TAXCODE": {
        return {
          ...state,
          createTaxCodeSuccess: false,
          createTaxCodeFailure: false,
          createTaxCodeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TAXCODE_SUCCESS": {             
        return {
          ...state,
          updateTaxCodeSuccess: true,
          updateTaxCodeData: action.payload.data,
        };
      }
      case "UPDATE_TAXCODE_FAILURE": {
        return {
          ...state,
          updateTaxCodeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TAXCODE": {
        return {
          ...state,
          updateTaxCodeSuccess: false,
          updateTaxCodeFailure: false,
          updateTaxCodeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TaxCodeReducer
  
  