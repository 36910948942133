const initialState = {
    userList: []  
  }
  
  const UnitReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_UNIT_SUCCESS": {             
        return {
          ...state,
          getUnitSuccess: true,
          getUnitList: action.payload.data,
        };
      }
      case "GET_UNIT_FAILURE": {
        return {
          ...state,
          getUnitFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_UNIT": {
        return {
          ...state,
          getUnitSuccess: false,
          getUserFailure: false,
          getUnitList: [],
          errorMessage: false
        };
      }

      case "GET_UNIT_FILTER_SUCCESS": {             
        return {
          ...state,
          getUnitFilterSuccess: true,
          getUnitFilterList: action.payload.data,
        };
      }
      case "GET_UNIT_FILTER_FAILURE": {
        return {
          ...state,
          getUnitFilterFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_FILTER_UNIT": {
        return {
          ...state,
          getUnitFilterSuccess: false,
          getUnitFilterFailure: false,
          getUnitFilterList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_UNIT_SUCCESS": {             
        return {
          ...state,
          createUnitSuccess: true,
          createUnitData: action.payload.data,
        };
      }
      case "CREATE_UNIT_FAILURE": {
        return {
          ...state,
          createUnitFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_UNIT": {
        return {
          ...state,
          createUnitSuccess: false,
          createUnitFailure: false,
          createUnitData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_UNIT_SUCCESS": {             
        return {
          ...state,
          updateUnitSuccess: true,
          updateUnitData: action.payload.data,
        };
      }
      case "UPDATE_UNIT_FAILURE": {
        return {
          ...state,
          updateUnitFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_UNIT": {
        return {
          ...state,
          updateUnitSuccess: false,
          updateUnitFailure: false,
          updateUnitData: false,
          errorMessage: false         
        };
      }
      case "GET_USER_DETAILS_SUCCESS": {             
        return {
          ...state,
          getUserDetailsSuccess: true,
          getUserDetailsList: action.payload.data,
        };
      }
      case "GET_USER_DETAILS_FAILURE": {
        return {
          ...state,
          getUserDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_USER_DETAILS": {
        return {
          ...state,
          getUserDetailsSuccess: false,
          getUserDetailsFailure: false,
          getUserDetailsList: [],
          errorMessage: false
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default UnitReducer
  
  