const initialState = {
    monthList: []  
  }
  
  const MonthReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_MONTH_SUCCESS": {             
        return {
          ...state,
          getMonthSuccess: true,
          getMonthList: action.payload.data,
        };
      }
      case "GET_MONTH_FAILURE": {
        return {
          ...state,
          getMonthFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_MONTH": {
        return {
          ...state,
          getMonthSuccess: false,
          getMonthFailure: false,
          getMonthList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_MONTH_SUCCESS": {             
        return {
          ...state,
          createMonthSuccess: true,
          createMonthData: action.payload.data,
        };
      }
      case "CREATE_MONTH_FAILURE": {
        return {
          ...state,
          createMonthFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_MONTH": {
        return {
          ...state,
          createMonthSuccess: false,
          createMonthFailure: false,
          createMonthData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_MONTH_SUCCESS": {             
        return {
          ...state,
          updateMonthSuccess: true,
          updateMonthData: action.payload.data,
        };
      }
      case "UPDATE_MONTH_FAILURE": {
        return {
          ...state,
          updateMonthFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_MONTH": {
        return {
          ...state,
          updateMonthSuccess: false,
          updateMonthFailure: false,
          updateMonthData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default MonthReducer
  
  