import { lazy } from "react";

const SubjectTeachersList = lazy(() => import("./subjectTeachersList"));

const subjectTeachersRoutes = [
  {
    path: "/subject-teachers",
    element: <SubjectTeachersList />,
  }
];

export default subjectTeachersRoutes;
