import { lazy } from "react";

const SupplierList = lazy(() => import("./supplierList"));

const supplierRoutes = [
  {
    path: "/supplier",
    element: <SupplierList />
  }
];

export default supplierRoutes;
