const initialState = {
    proofTypeList: []
}

const ProductReducer = function (state = initialState, action) {
    switch (action.type) {
        // Brand
        case "GET_BRAND_SUCCESS": {
            return {
                ...state,
                getBrandSuccess: true,
                getBrandList: action.payload.data,
            };
        }
        case "GET_BRAND_FAILURE": {
            return {
                ...state,
                getBrandFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_BRAND": {
            return {
                ...state,
                getBrandSuccess: false,
                getBrandFailure: false,
                getBrandList: [],
                errorMessage: false
            };
        }
        case "CREATE_BRAND_SUCCESS": {
            return {
                ...state,
                createBrandSuccess: true,
                createBrandData: action.payload.data,
            };
        }
        case "CREATE_BRAND_FAILURE": {
            return {
                ...state,
                createBrandFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_BRAND": {
            return {
                ...state,
                createBrandSuccess: false,
                createBrandFailure: false,
                createBrandData: false,
                errorMessage: false
            };
        }
        case "UPDATE_BRAND_SUCCESS": {
            return {
                ...state,
                updateBrandSuccess: true,
                updateBrandData: action.payload.data,
            };
        }
        case "UPDATE_BRAND_FAILURE": {
            return {
                ...state,
                updateBrandFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_BRAND": {
            return {
                ...state,
                updateBrandSuccess: false,
                updateBrandFailure: false,
                updateBrandData: false,
                errorMessage: false
            };
        }


        // Category
        case "GET_CATEGORY_SUCCESS": {
            return {
                ...state,
                getCategorySuccess: true,
                getCategoryList: action.payload.data,
            };
        }
        case "GET_CATEGORY_FAILURE": {
            return {
                ...state,
                getCategoryFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_CATEGORY": {
            return {
                ...state,
                getCategorySuccess: false,
                getCategoryFailure: false,
                getCategoryList: [],
                errorMessage: false
            };
        }
        case "CREATE_CATEGORY_SUCCESS": {
            return {
                ...state,
                createCategorySuccess: true,
                createCategoryData: action.payload.data,
            };
        }
        case "CREATE_CATEGORY_FAILURE": {
            return {
                ...state,
                createCategoryFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_CATEGORY": {
            return {
                ...state,
                createCategorySuccess: false,
                createCategoryFailure: false,
                createCategoryData: false,
                errorMessage: false
            };
        }
        case "UPDATE_CATEGORY_SUCCESS": {
            return {
                ...state,
                updateCategorySuccess: true,
                updateCategoryData: action.payload.data,
            };
        }
        case "UPDATE_CATEGORY_FAILURE": {
            return {
                ...state,
                updateCategoryFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_CATEGORY": {
            return {
                ...state,
                updateCategorySuccess: false,
                updateCategoryFailure: false,
                updateCategoryData: false,
                errorMessage: false
            };
        }

        // Material Type
        case "GET_MATERIAL_TYPE_SUCCESS": {
            return {
                ...state,
                getMaterialTypeSuccess: true,
                getMaterialTypeList: action.payload.data,
            };
        }
        case "GET_MATERIAL_TYPE_FAILURE": {
            return {
                ...state,
                getMaterialTypeFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_MATERIAL_TYPE": {
            return {
                ...state,
                getMaterialTypeSuccess: false,
                getMaterialTypeFailure: false,
                getMaterialTypeList: [],
                errorMessage: false
            };
        }
        case "CREATE_MATERIAL_TYPE_SUCCESS": {
            return {
                ...state,
                createMaterialTypeSuccess: true,
                createMaterialTypeData: action.payload.data,
            };
        }
        case "CREATE_MATERIAL_TYPE_FAILURE": {
            return {
                ...state,
                createMaterialTypeFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_MATERIAL_TYPE": {
            return {
                ...state,
                createMaterialTypeSuccess: false,
                createMaterialTypeFailure: false,
                createMaterialTypeData: false,
                errorMessage: false
            };
        }
        case "UPDATE_MATERIAL_TYPE_SUCCESS": {
            return {
                ...state,
                updateMaterialTypeSuccess: true,
                updateMaterialTypeData: action.payload.data,
            };
        }
        case "UPDATE_MATERIAL_TYPE_FAILURE": {
            return {
                ...state,
                updateMaterialTypeFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_MATERIAL_TYPE": {
            return {
                ...state,
                updateMaterialTypeSuccess: false,
                updateMaterialTypeFailure: false,
                updateMaterialTypeData: false,
                errorMessage: false
            };
        }

        // Material
        case "GET_MATERIAL_INFO_SUCCESS": {
            return {
                ...state,
                getMaterialInfoSuccess: true,
                getMaterialInfoList: action.payload.data,
            };
        }
        case "GET_MATERIAL_INFO_FAILURE": {
            return {
                ...state,
                getMaterialInfoFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_MATERIAL_INFO_FAILURE": {
            return {
                ...state,
                getMaterialInfoSuccess: false,
                getMaterialInfoFailure: false,
                getMaterialInfoList: [],
                errorMessage: false
            };
        }
        case "GET_MATERIAL_SUCCESS": {
            return {
                ...state,
                getMaterialSuccess: true,
                getMaterialList: action.payload.data,
            };
        }
        case "GET_MATERIAL_FAILURE": {
            return {
                ...state,
                getMaterialFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_MATERIAL": {
            return {
                ...state,
                getMaterialSuccess: false,
                getMaterialFailure: false,
                getMaterialList: [],
                errorMessage: false
            };
        }
        case "CREATE_MATERIAL_SUCCESS": {
            return {
                ...state,
                createMaterialSuccess: true,
                createMaterialData: action.payload.data,
            };
        }
        case "CREATE_MATERIAL_FAILURE": {
            return {
                ...state,
                createMaterialFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_MATERIAL": {
            return {
                ...state,
                createMaterialSuccess: false,
                createMaterialFailure: false,
                createMaterialData: false,
                errorMessage: false
            };
        }
        case "UPDATE_MATERIAL_SUCCESS": {
            return {
                ...state,
                updateMaterialSuccess: true,
                updateMaterialData: action.payload.data,
            };
        }
        case "UPDATE_MATERIAL_FAILURE": {
            return {
                ...state,
                updateMaterialFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_MATERIAL": {
            return {
                ...state,
                updateMaterialSuccess: false,
                updateMaterialFailure: false,
                updateMaterialData: false,
                errorMessage: false
            };
        }

        //Available Material
        case "GET_AVAILABEL_MATERIAL_SUCCESS": {
            return {
                ...state,
                getAvailableMaterialSuccess: true,
                getAvailableMaterialList: action.payload.data,
            };
        }
        case "GET_AVAILABEL_MATERIAL_FAILURE": {
            return {
                ...state,
                getAvailableMaterialFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_AVAILABEL_MATERIAL": {
            return {
                ...state,
                getAvailableMaterialSuccess: false,
                getAvailableMaterialFailure: false,
                getAvailableMaterialList: [],
                errorMessage: false
            };
        }


        // Supplier
        case "GET_SUPPLIER_SUCCESS": {
            return {
                ...state,
                getSupplierSuccess: true,
                getSupplierList: action.payload.data,
            };
        }
        case "GET_SUPPLIER_FAILURE": {
            return {
                ...state,
                getSupplierFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_SUPPLIER": {
            return {
                ...state,
                getSupplierSuccess: false,
                getSupplierFailure: false,
                getSupplierList: [],
                errorMessage: false
            };
        }
        case "CREATE_SUPPLIER_SUCCESS": {
            return {
                ...state,
                createSupplierSuccess: true,
                createSupplierData: action.payload.data,
            };
        }
        case "CREATE_SUPPLIER_FAILURE": {
            return {
                ...state,
                createSupplierFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_SUPPLIER": {
            return {
                ...state,
                createSupplierSuccess: false,
                createSupplierFailure: false,
                createSupplierData: false,
                errorMessage: false
            };
        }
        case "UPDATE_SUPPLIER_SUCCESS": {
            return {
                ...state,
                updateSupplierSuccess: true,
                updateSupplierData: action.payload.data,
            };
        }
        case "UPDATE_SUPPLIER_FAILURE": {
            return {
                ...state,
                updateSupplierFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_SUPPLIER": {
            return {
                ...state,
                updateSupplierSuccess: false,
                updateSupplierFailure: false,
                updateSupplierData: false,
                errorMessage: false
            };
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default ProductReducer

