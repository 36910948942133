import { spLoginAPI,serviceProviderDetails } from './APIContainer';
import { returnApiCallPromise } from './ApiContent';
// import jwt from 'jsonwebtoken'
import axios from "axios";
const JWT_SECRET = 'jwt_secret_key'
const JWT_VALIDITY = '7 days'

export function UserLogin_1(loginObject) {
  let subURL = "http://192.168.1.73:5900/access-management/sp-login";
  
  return (
    returnApiCallPromise("POST", subURL, loginObject)
      .then(response => {
        const token = "Bearer " + response.headers.token;
        console.log("token----"+token)
       // config.headers.Authorization =  token;
        const result = {
          loginSuccess: true,
          payload: response.data,
        }
       
        return result;
        /* const headerResp = response.headers;
        const accessToken = jwt.sign({ userId: parseInt(headerResp.user_id) }, JWT_SECRET, {
          expiresIn: JWT_VALIDITY,
        })

        return {
          data : {
            accessToken,
            status:true,
            user: response.data.data,
            userId: headerResp.user_id,
            orgId: headerResp.org_id,
          }
        } */
        

        // return response.data.data
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data;
          }
        }
        const result = {
          loginFailed: true,
          errorMessage: errorMessage?.message
        }
        return result;
        return {
            data : {
            status:true,
            message : errorMessage
          }
        }
        /* dispatch({
          type: 'LOGIN_FAILURE',
          data: errorMessage
        }) */
      })
  )
}

export async function UserLogin(loginObject) {
  // let subURL = "http://192.168.1.73:5900/access-management/sp-login";  
  return (
    returnApiCallPromise("POST", spLoginAPI, loginObject)
      .then(response => {      
        // const token = "Bearer " + response.headers.token; 
        global.userId = response.headers.userid
        const token = response.headers.token;       
        const result = {
          loginSuccess: true,
          payload: response.data,
          accessToken: token
        }
       
        return result;
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data;
          }
        }        
        const result = {
          loginFailed: true,
          errorMessage: errorMessage?.message
        }
        return result;
      })
  )
}

/* export function userLoginAPI(loginObject) {
  // let subURL = "http://192.168.1.73:5900/access-management/sp-login";  

  return (
    returnApiCallPromise("POST", userLogin, loginObject)
      .then(response => {                           
        // const token = "Bearer " + response.headers.token;       
        const token = response.headers.token;       
        const result = {
          loginSuccess: true,
          payload: response.data,
          accessToken: token
        }
       
        return result;
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data;
          }
        }        
        const result = {
          loginFailed: true,
          errorMessage: errorMessage?.message
        }
        return result;
      })
  )
} */

export function userDetailsInfo(loginObject) {
  // let subURL = "http://192.168.1.73:5900/access-management/sp-login";  

  return (
    returnApiCallPromise("GET", serviceProviderDetails)
      .then(response => {     
        const result = {
          userInfoSuccess: true,
          payload: response.data.data,
          pages: response?.data?.data[0]?.pages || []
        }
       
        return result;
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data;
          }
        }        
        const result = {
          userInfoFailed: true,
          errorMessage: errorMessage?.message
        }
        return result;
      })
  )
}

export function GetUserInfo(getObject) {    
  return (
    returnApiCallPromise("GET", "access-management/user", { params: getObject })
      .then(response => {
        
        return {
          data: response.data.data
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data;
          }
        }
        return {
          data: { message: errorMessage }
        }
      })
  )
}

export function UserLogout() {

  return (dispatch) => {
    dispatch({ type: 'LOG_OUT' });
    /* returnApiCallPromise(global.GET, patameter)
        .then(response => {               
            dispatch({
                type: 'MEMBERSHIP_FETCH_SUCCESS',
                data: response.data
            })
        }).catch(error => {
            dispatch({
                type: 'MEMBERSHIP_FETCH_FAILURE',
                data: {
                    message: error
                }
            })
        }) */
  }
}