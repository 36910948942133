const initialState = {
    holidaysList: []  
  }
  
  const HolidaysReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_HOLIDAYS_SUCCESS": {       
        return {
          ...state,
          getHolidaysSuccess: true,
          getHolidaysList: action.payload.data,
        };
      }
      case "GET_HOLIDAYS_FAILURE": {
        return {
          ...state,
          getHolidaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_HOLIDAYS": {
        return {
          ...state,
          getHolidaysSuccess: false,
          getHolidaysFailure: false,
          getHolidaysList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_HOLIDAYS_SUCCESS": {             
        return {
          ...state,
          createHolidaysSuccess: true,
          createHolidaysData: action.payload.data,
        };
      }
      case "CREATE_HOLIDAYS_FAILURE": {
        return {
          ...state,
          createHolidaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_HOLIDAYS": {
        return {
          ...state,
          createHolidaysSuccess: false,
          createHolidaysFailure: false,
          createHolidaysData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_HOLIDAYS_SUCCESS": {             
        return {
          ...state,
          updateHolidaysSuccess: true,
          updateHolidaysData: action.payload.data,
        };
      }
      case "UPDATE_HOLIDAYS_FAILURE": {
        return {
          ...state,
          updateHolidaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_HOLIDAYS": {
        return {
          ...state,
          updateHolidaysSuccess: false,
          updateHolidaysFailure: false,
          updateHolidaysData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default HolidaysReducer
  
  