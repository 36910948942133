const initialState = {
    employeeTypeList: []  
  }
  
  const EmployeeTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_EMPLOYEE_TYPE_SUCCESS": {             
        return {
          ...state,
          getEmployeeTypeSuccess: true,
          getEmployeeTypeList: action.payload.data,
        };
      }
      case "GET_EMPLOYEE_TYPE_FAILURE": {
        return {
          ...state,
          getEmployeeTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EMPLOYEE_TYPE": {
        return {
          ...state,
          getEmployeeTypeSuccess: false,
          getEmployeeTypeFailure: false,
          getEmployeeTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_EMPLOYEE_TYPE_SUCCESS": {             
        return {
          ...state,
          createEmployeeTypeSuccess: true,
          createEmployeeTypeData: action.payload.data,
        };
      }
      case "CREATE_EMPLOYEE_TYPE_FAILURE": {
        return {
          ...state,
          createEmployeeTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_EMPLOYEE_TYPE": {
        return {
          ...state,
          createEmployeeTypeSuccess: false,
          createEmployeeTypeFailure: false,
          createEmployeeTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_EMPLOYEE_TYPE_SUCCESS": {             
        return {
          ...state,
          updateEmployeeTypeSuccess: true,
          updateEmployeeTypeData: action.payload.data,
        };
      }
      case "UPDATE_EMPLOYEE_TYPE_FAILURE": {
        return {
          ...state,
          updateEmployeeTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_EMPLOYEE_TYPE": {
        return {
          ...state,
          updateEmployeeTypeSuccess: false,
          updateEmployeeTypeFailure: false,
          updateEmployeeTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EmployeeTypeReducer
  
  