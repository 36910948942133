import { lazy } from "react";

const KnowledgeMaterialList = lazy(() => import("./knowledgeMaterialList"));

const KnowledgeMaterialRoutes = [
  {
    path: "/knowledge-material-list",
    element: <KnowledgeMaterialList />,
  }
];

export default KnowledgeMaterialRoutes;
