const initialState = {
    invoiceList: []  
  }
  
  const InvoiceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_INVOICE_SUCCESS": {             
        return {
          ...state,
          getInvoiceSuccess: true,
          getInvoiceList: action.payload.data,
        };
      }
      case "GET_INVOICE_FAILURE": {
        return {
          ...state,
          getInvoiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_INVOICE": {
        return {
          ...state,
          getInvoiceSuccess: false,
          getInvoiceFailure: false,
          getInvoiceList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_INVOICE_SUCCESS": {             
        return {
          ...state,
          createInvoiceSuccess: true,
          createInvoiceData: action.payload.data,
        };
      }
      case "CREATE_INVOICE_FAILURE": {
        return {
          ...state,
          createInvoiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_INVOICE": {
        return {
          ...state,
          createInvoiceSuccess: false,
          createInvoiceFailure: false,
          createInvoiceData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_INVOICE_SUCCESS": {             
        return {
          ...state,
          updateInvoiceSuccess: true,
          updateInvoiceData: action.payload.data,
        };
      }
      case "UPDATE_INVOICE_FAILURE": {
        return {
          ...state,
          updateInvoiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_INVOICE": {
        return {
          ...state,
          updateInvoiceSuccess: false,
          updateInvoiceFailure: false,
          updateInvoiceData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default InvoiceReducer
  
  