const initialState = {
    nationalityList: []  
  }
  
  const NationalityReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_NATIONALITY_SUCCESS": {             
        return {
          ...state,
          getNationalitySuccess: true,
          getNationalityList: action.payload.data,
        };
      }
      case "GET_NATIONALITY_FAILURE": {
        return {
          ...state,
          getNationalityFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_NATIONALITY": {
        return {
          ...state,
          getNationalitySuccess: false,
          getNationalityFailure: false,
          getNationalityList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_NATIONALITY_SUCCESS": {             
        return {
          ...state,
          createNationalitySuccess: true,
          createNationalityData: action.payload.data,
        };
      }
      case "CREATE_NATIONALITY_FAILURE": {
        return {
          ...state,
          createNationalityFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_NATIONALITY": {
        return {
          ...state,
          createNationalitySuccess: false,
          createNationalityFailure: false,
          createNationalityData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_NATIONALITY_SUCCESS": {             
        return {
          ...state,
          updateNationalitySuccess: true,
          updateNationalityData: action.payload.data,
        };
      }
      case "UPDATE_NATIONALITY_FAILURE": {
        return {
          ...state,
          updateNationalityFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_NATIONALITY": {
        return {
          ...state,
          updateNationalitySuccess: false,
          updateNationalityFailure: false,
          updateNationalityData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default NationalityReducer
  
  