const initialState = {
    warehouseList: []  
  }
  
  const WarehouseReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_WAREHOUSE_SUCCESS": {             
        return {
          ...state,
          getWarehouseSuccess: true,
          getWarehouseList: action.payload.data,
        };
      }
      case "GET_WAREHOUSE_FAILURE": {
        return {
          ...state,
          getWarehouseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_WAREHOUSE": {
        return {
          ...state,
          getWarehouseSuccess: false,
          getWarehouseFailure: false,
          getWarehouseList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_WAREHOUSE_SUCCESS": {             
        return {
          ...state,
          createWarehouseSuccess: true,
          createWarehouseData: action.payload.data,
        };
      }
      case "CREATE_WAREHOUSE_FAILURE": {
        return {
          ...state,
          createWarehouseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_WAREHOUSE": {
        return {
          ...state,
          createWarehouseSuccess: false,
          createWarehouseFailure: false,
          createWarehouseData: false,
        };
      }
      case "CREATE_USER_SUCCESS": {             
        return {
          ...state,
          createUserSuccess: true,
          createUserData: action.payload.data,
        };
      }
      case "CREATE_USER_FAILURE": {
        return {
          ...state,
          createUserFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_USER": {
        return {
          ...state,
          createUserSuccess: false,
          createUserFailure: false,
          createUserData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_WAREHOUSE_SUCCESS": {             
        return {
          ...state,
          updateWarehouseSuccess: true,
          updateWarehouseData: action.payload.data,
        };
      }
      case "UPDATE_WAREHOUSE_FAILURE": {
        return {
          ...state,
          updateWarehouseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_WAREHOUSE": {
        return {
          ...state,
          updateWarehouseSuccess: false,
          updateWarehouseFailure: false,
          updateWarehouseData: false,
        };
      }
      case "UPDATE_USER_SUCCESS": {             
        return {
          ...state,
          updateUserSuccess: true,
          updateUserData: action.payload.data,
        };
      }
      case "UPDATE_USER_FAILURE": {
        return {
          ...state,
          updateUserFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_USER": {
        return {
          ...state,
          updateUserSuccess: false,
          updateUserFailure: false,
          updateUserData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default WarehouseReducer
  
  
