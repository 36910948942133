const initialState = {
    KnowledgeList: []  
  }
  
  const KnowledgeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_KNOWLEDGE_MATERIAL_SUCCESS": {             
        return {
          ...state,
          getKnowledgeSuccess: true,
          getKnowledgeList: action.payload.data,
        };
      }
      case "GET_KNOWLEDGE_MATERIAL_FAILURE": {
        return {
          ...state,
          getKnowledgeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_KNOWLEDGE_MATERIAL": {
        return {
          ...state,
          getKnowledgeSuccess: false,
          getKnowledgeFailure: false,
          getKnowledgeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_KNOWLEDGE_MATERIAL_SUCCESS": {             
        return {
          ...state,
          createKnowledgeSuccess: true,
          createKnowledgeData: action.payload.data,
        };
      }
      case "CREATE_KNOWLEDGE_MATERIAL_FAILURE": {
        return {
          ...state,
          createKnowledgeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_KNOWLEDGE_MATERIAL": {
        return {
          ...state,
          createKnowledgeSuccess: false,
          createKnowledgeFailure: false,
          createKnowledgeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_KNOWLEDGE_MATERIAL_SUCCESS": {             
        return {
          ...state,
          updateKnowledgeSuccess: true,
          updateKnowledgeData: action.payload.data,
        };
      }
      case "UPDATE_KNOWLEDGE_MATERIAL_FAILURE": {
        return {
          ...state,
          updateKnowledgeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_KNOWLEDGE_MATERIAL": {
        return {
          ...state,
          updateKnowledgeSuccess: false,
          updateKnowledgeFailure: false,
          updateKnowledgeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default KnowledgeReducer
  
  