import { lazy } from "react";

const HolidaysList = lazy(() => import("./holidaysList"));

const holidaysRoutes = [
  {
    path: "/holidays",
    element: <HolidaysList />,
  }
];

export default holidaysRoutes;
