import { lazy } from "react";

const TimeTableList = lazy(() => import("./timeTableList"));

const timeTableRoutes = [
  {
    path: "/time-table",
    element: <TimeTableList />,
  }
];

export default timeTableRoutes;
