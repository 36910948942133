import { lazy } from "react";

const InvoiceList = lazy(() => import("./invoiceList"));

const InvoiceRoutes = [
  {
    path: "/invoices-list",
    element: <InvoiceList />,
  }
];

export default InvoiceRoutes;
