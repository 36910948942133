const initialState = {
    documentList: []
}

const DocumentReducer = function (state = initialState, action) {
    switch (action.type) {
        
        case "GET_DOCUMENT_SUCCESS": {
            return {
                ...state,
                getDesignationSuccess: true,
                getDesignationList: action.payload.data,
            };
        }
        case "GET_DOCUMENT_FAILURE": {
            return {
                ...state,
                getDesignationFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_DOCUMENT": {
            return {
                ...state,
                getDesignationSuccess: false,
                getDesignationFailure: false,
                getDesignationList: [],
                errorMessage: false
            };
        }

        case "CREATE_DOCUMENT_SUCCESS": {
            return {
                ...state,
                createDocumentSuccess: true,
                createDocumentData: action.payload.data,
            };
        }
        case "CREATE_DOCUMENT_FAILURE": {
            return {
                ...state,
                createDocumentFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_DOCUMENT": {
            return {
                ...state,
                createDocumentSuccess: false,
                createDocumentFailure: false,
                createDocumentData: false,
                errorMessage: false
            };
        }

        case "CREATE_FILE_SUCCESS": {
            return {
                ...state,
                createFileSuccess: true,
                createFileData: action.payload.data,
            };
        }
        case "CREATE_FILE_FAILURE": {
            return {
                ...state,
                createFileFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_FILE": {
            return {
                ...state,
                createFileSuccess: false,
                createFileFailure: false,
                createFileData: false,
                errorMessage: false
            };
        }

        case "CREATE_STREAM_SUCCESS": {
            return {
                ...state,
                createStreamSuccess: true,
                createStreamData: action.payload.data,
            };
        }
        case "CREATE_STREAM_FAILURE": {
            return {
                ...state,
                createStreamFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_STREAM": {
            return {
                ...state,
                createStreamSuccess: false,
                createStreamFailure: false,
                createStreamData: false,
                errorMessage: false
            };
        }

        

        case "UPDATE_DOCUMENT_SUCCESS": {
            return {
                ...state,
                updateDesignationSuccess: true,
                updateDesignationData: action.payload.data,
            };
        }
        case "UPDATE_DOCUMENT_FAILURE": {
            return {
                ...state,
                updateDesignationFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_DOCUMENT": {
            return {
                ...state,
                updateDesignationSuccess: false,
                updateDesignationFailure: false,
                updateDesignationData: false,
                errorMessage: false
            };
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default DocumentReducer

