const initialState = {
    subjectList: []  
  }
  
  const SubjectReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SUBJECT_SUCCESS": {             
        return {
          ...state,
          getSubjectSuccess: true,
          getSubjectList: action.payload.data,
        };
      }
      case "GET_SUBJECT_FAILURE": {
        return {
          ...state,
          getSubjectFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SUBJECT": {
        return {
          ...state,
          getSubjectSuccess: false,
          getSubjectFailure: false,
          getSubjectList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SUBJECT_SUCCESS": {             
        return {
          ...state,
          createSubjectSuccess: true,
          createSubjectData: action.payload.data,
        };
      }
      case "CREATE_SUBJECT_FAILURE": {
        return {
          ...state,
          createSubjectFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SUBJECT": {
        return {
          ...state,
          createSubjectSuccess: false,
          createSubjectFailure: false,
          createSubjectData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SUBJECT_SUCCESS": {             
        return {
          ...state,
          updateSubjectSuccess: true,
          updateSubjectData: action.payload.data,
        };
      }
      case "UPDATE_SUBJECT_FAILURE": {
        return {
          ...state,
          updateSubjectFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SUBJECT": {
        return {
          ...state,
          updateSubjectSuccess: false,
          updateSubjectFailure: false,
          updateSubjectData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SubjectReducer
  
  