const initialState = {
    studentAttendance: []  
  }
  
  const StudentAttendanceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_STUDENT_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          getStudentAttendanceSuccess: true,
          getStudentAttendanceList: action.payload.data,
        };
      }
      case "GET_STUDENT_ATTENDANCE_FAILURE": {
        return {
          ...state,
          getStudentAttendanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_ATTENDANCE": {
        return {
          ...state,
          getStudentAttendanceSuccess: false,
          getStudentAttendanceFailure: false,
          getStudentAttendanceList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_STUDENT_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          createStudentAttendanceSuccess: true,
          createStudentAttendanceData: action.payload.data,
        };
      }
      case "CREATE_STUDENT_ATTENDANCE_FAILURE": {
        return {
          ...state,
          createStudentAttendanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_STUDENT_ATTENDANCE": {
        return {
          ...state,
          createStudentAttendanceSuccess: false,
          createStudentAttendanceFailure: false,
          createStudentAttendanceData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_STUDENT_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          updateStudentAttendanceSuccess: true,
          updateStudentAttendanceData: action.payload.data,
        };
      }
      case "UPDATE_STUDENT_ATTENDANCE_FAILURE": {
        return {
          ...state,
          updateStudentAttendanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_STUDENT_ATTENDANCE": {
        return {
          ...state,
          updateStudentAttendanceSuccess: false,
          updateStudentAttendanceFailure: false,
          updateStudentAttendanceData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default StudentAttendanceReducer
  
  