const initialState = {
    leaveSettingsList: []  
  }
  
  const LeaveSettingsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_LEAVE_SETTINGS_SUCCESS": {             
        return {
          ...state,
          getLeaveSettingsSuccess: true,
          getLeaveSettingsList: action.payload.data,
        };
      }
      case "GET_LEAVE_SETTINGS_FAILURE": {
        return {
          ...state,
          getLeaveSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LEAVE_SETTINGS": {
        return {
          ...state,
          getLeaveSettingsSuccess: false,
          getLeaveSettingsFailure: false,
          getLeaveSettingsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LEAVE_SETTINGS_SUCCESS": {             
        return {
          ...state,
          createLeaveSettingsSuccess: true,
          createLeaveSettingsData: action.payload.data,
        };
      }
      case "CREATE_LEAVE_SETTINGS_FAILURE": {
        return {
          ...state,
          createLeaveSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LEAVE_SETTINGS": {
        return {
          ...state,
          createLeaveSettingsSuccess: false,
          createLeaveSettingsFailure: false,
          createLeaveSettingsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LEAVE_SETTINGS_SUCCESS": {             
        return {
          ...state,
          updateLeaveSettingsSuccess: true,
          updateLeaveSettingsData: action.payload.data,
        };
      }
      case "UPDATE_LEAVE_SETTINGS_FAILURE": {
        return {
          ...state,
          updateLeaveSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LEAVE_SETTINGS": {
        return {
          ...state,
          updateLeaveSettingsSuccess: false,
          updateLeaveSettingsFailure: false,
          updateLeaveSettingsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default LeaveSettingsReducer
  
  