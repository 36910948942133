import { lazy } from "react";

const HomeWorkTypeList = lazy(() => import("./homeWorkTypeList"));

const homeWorkTypeRoutes = [
  {
    path: "/homework-type",
    element: <HomeWorkTypeList />,
  }
];

export default homeWorkTypeRoutes;
