import { lazy } from "react";

const MarkSheetList = lazy(() => import("./markSheetList"));

const markSheetRoutes = [
  {
    path: "/mark-sheet",
    element: <MarkSheetList />,
  }
];

export default markSheetRoutes;
