const initialState = {
    deliveryList: []  
  }
  
  const DeliveryReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DELIVERY_SUCCESS": {             
        return {
          ...state,
          getDeliverySuccess: true,
          getDeliveryList: action.payload.data,
        };
      }
      case "GET_DELIVERY_FAILURE": {
        return {
          ...state,
          getDeliveryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DELIVERY": {
        return {
          ...state,
          getDeliverySuccess: false,
          getDeliveryFailure: false,
          getDeliveryList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_DELIVERY_SUCCESS": {             
        return {
          ...state,
          createDeliverySuccess: true,
          createDeliveryData: action.payload.data,
        };
      }
      case "CREATE_DELIVERY_FAILURE": {
        return {
          ...state,
          createDeliveryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DELIVERY": {
        return {
          ...state,
          createDeliverySuccess: false,
          createDeliveryFailure: false,
          createDeliveryData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DELIVERY_SUCCESS": {             
        return {
          ...state,
          updateDeliverySuccess: true,
          updateDeliveryData: action.payload.data,
        };
      }
      case "UPDATE_DELIVERY_FAILURE": {
        return {
          ...state,
          updateDeliveryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DELIVERY": {
        return {
          ...state,
          updateDeliverySuccess: false,
          updateDeliveryFailure: false,
          updateDeliveryData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DeliveryReducer
  
  