const initialState = {
    religionList: []  
  }
  
  const ReligionReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_RELIGION_SUCCESS": {             
        return {
          ...state,
          getReligionSuccess: true,
          getReligionList: action.payload.data,
        };
      }
      case "GET_RELIGION_FAILURE": {
        return {
          ...state,
          getReligionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_RELIGION": {
        return {
          ...state,
          getReligionSuccess: false,
          getReligionFailure: false,
          getReligionList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_RELIGION_SUCCESS": {             
        return {
          ...state,
          createReligionSuccess: true,
          createReligionData: action.payload.data,
        };
      }
      case "CREATE_RELIGION_FAILURE": {
        return {
          ...state,
          createReligionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_RELIGION": {
        return {
          ...state,
          createReligionSuccess: false,
          createReligionFailure: false,
          createReligionData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_RELIGION_SUCCESS": {             
        return {
          ...state,
          updateReligionSuccess: true,
          updateReligionData: action.payload.data,
        };
      }
      case "UPDATE_RELIGION_FAILURE": {
        return {
          ...state,
          updateReligionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_RELIGION": {
        return {
          ...state,
          updateReligionSuccess: false,
          updateReligionFailure: false,
          updateReligionData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ReligionReducer
  
  