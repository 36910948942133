const initialState = {
    subjectTeachersList: []  
  }
  
  const SubjectTeachersReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SUBJECT_TEACHERS_SUCCESS": {             
        return {
          ...state,
          getSubjectTeachersSuccess: true,
          getSubjectTeachersList: action.payload.data,
        };
      }
      case "GET_SUBJECT_TEACHERS_FAILURE": {
        return {
          ...state,
          getSubjectTeachersFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SUBJECT_TEACHERS": {
        return {
          ...state,
          getSubjectTeachersSuccess: false,
          getSubjectTeachersFailure: false,
          getSubjectTeachersList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SUBJECT_TEACHERS_SUCCESS": {             
        return {
          ...state,
          createSubjectTeachersSuccess: true,
          createSubjectTeachersData: action.payload.data,
        };
      }
      case "CREATE_SUBJECT_TEACHERS_FAILURE": {
        return {
          ...state,
          createSubjectTeachersFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SUBJECT_TEACHERS": {
        return {
          ...state,
          createSubjectTeachersSuccess: false,
          createSubjectTeachersFailure: false,
          createSubjectTeachersData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SUBJECT_TEACHERS_SUCCESS": {             
        return {
          ...state,
          updateSubjectTeachersSuccess: true,
          updateSubjectTeachersData: action.payload.data,
        };
      }
      case "UPDATE_SUBJECT_TEACHERS_FAILURE": {
        return {
          ...state,
          updateSubjectTeachersFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SUBJECT_TEACHERS": {
        return {
          ...state,
          updateSubjectTeachersSuccess: false,
          updateSubjectTeachersFailure: false,
          updateSubjectTeachersData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SubjectTeachersReducer
  
  