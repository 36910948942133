import { lazy } from "react";

const UnitTypeList = lazy(() => import("./unitTypeList"));

const UnitTypeRoutes = [
  {
    path: "/unit-type",
    element: <UnitTypeList />,
  }
];

export default UnitTypeRoutes;
