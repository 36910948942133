const initialState = {
    attendanceTypeList: []  
  }
  
  const AttendanceTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ATTENDANCE_TYPE_SUCCESS": {             
        return {
          ...state,
          getAttendanceTypeSuccess: true,
          getAttendanceTypeList: action.payload.data,
        };
      }
      case "GET_ATTENDANCE_TYPE_FAILURE": {
        return {
          ...state,
          getAttendanceTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ATTENDANCE_TYPE": {
        return {
          ...state,
          getAttendanceTypeSuccess: false,
          getAttendanceTypeFailure: false,
          getAttendanceTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ATTENDANCE_TYPE_SUCCESS": {             
        return {
          ...state,
          createAttendanceTypeSuccess: true,
          createAttendanceTypeData: action.payload.data,
        };
      }
      case "CREATE_ATTENDANCE_TYPE_FAILURE": {
        return {
          ...state,
          createAttendanceTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ATTENDANCE_TYPE": {
        return {
          ...state,
          createAttendanceTypeSuccess: false,
          createAttendanceTypeFailure: false,
          createAttendanceTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ATTENDANCE_TYPE_SUCCESS": {             
        return {
          ...state,
          updateAttendanceTypeSuccess: true,
          updateAttendanceTypeData: action.payload.data,
        };
      }
      case "UPDATE_ATTENDANCE_TYPE_FAILURE": {
        return {
          ...state,
          updateAttendanceTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ATTENDANCE_TYPE": {
        return {
          ...state,
          updateAttendanceTypeSuccess: false,
          updateAttendanceTypeFailure: false,
          updateAttendanceTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default AttendanceTypeReducer
  
  