const initialState = {
    enquiryList: []
  }
  
  const EnquiryReducer = function (state = initialState, action) {
    switch (action.type) {
      case "GET_ENQUIRY_SUCCESS": {
        return {
          ...state,
          getEnquirySuccess: true,
          getEnquiryList: action.payload.data,
        };
      }
      case "GET_ENQUIRY_FAILURE": {
        return {
          ...state,
          getEnquiryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ENQUIRY": {
        return {
          ...state,
          getEnquirySuccess: false,
          getEnquiryFailure: false,
          getEnquiryList: [],
          errorMessage: false
        };
      }
  
      case "GET_ENQUIRY_LIST_SUCCESS": {
        return {
          ...state,
          getEnquiryListSuccess: true,
          getEnquiryListList: action.payload.data,
        };
      }
      case "GET_ENQUIRY_LIST_FAILURE": {
        return {
          ...state,
          getEnquiryListFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ENQUIRY_LIST": {
        return {
          ...state,
          getEnquiryListSuccess: false,
          getEnquiryListFailure: false,
          getEnquiryListList: [],
          errorMessage: false
        };
      }
  
      case "CREATE_ENQUIRY_SUCCESS": {
        return {
          ...state,
          createEnquirySuccess: true,
          createEnquiryData: action.payload.data,
        };
      }
      case "CREATE_ENQUIRY_FAILURE": {
        return {
          ...state,
          createEnquiryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ENQUIRY": {
        return {
          ...state,
          createEnquirySuccess: false,
          createEnquiryFailure: false,
          createEnquiryData: false,
          errorMessage: false
        };
      }
  
      case "UPDATE_ENQUIRY_SUCCESS": {
        return {
          ...state,
          updateEnquirySuccess: true,
          updateEnquiryData: action.payload.data,
        };
      }
      case "UPDATE_ENQUIRY_FAILURE": {
        return {
          ...state,
          updateEnquiryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ENQUIRY": {
        return {
          ...state,
          updateEnquirySuccess: false,
          updateEnquiryFailure: false,
          updateEnquiryData: false,
          errorMessage: false
        };
      }
  
      case "DELETE_ENQUIRY_DETAILS_SUCCESS": {
        return {
          ...state,
          deleteEnquiryDetailsSuccess: true,
        };
      }
      case "DELETE_ENQUIRY_DETAILS_FAILURE": {
        return {
          ...state,
          deleteEnquiryDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_ENQUIRY_DETAILS": {
        return {
          ...state,
          deleteEnquiryDetailsSuccess: false,
          deleteEnquiryDetailsFailure: false,
          errorMessage: false
        };
      }
  
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EnquiryReducer
  
  