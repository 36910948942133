const initialState = {
    genderList: []  
  }
  
  const GenderReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_GENDER_SUCCESS": {             
        return {
          ...state,
          getGenderSuccess: true,
          getGenderList: action.payload.data,
        };
      }
      case "GET_GENDER_FAILURE": {
        return {
          ...state,
          getGenderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_GENDER": {
        return {
          ...state,
          getGenderSuccess: false,
          getGenderFailure: false,
          getGenderList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_GENDER_SUCCESS": {             
        return {
          ...state,
          createGenderSuccess: true,
          createGenderData: action.payload.data,
        };
      }
      case "CREATE_GENDER_FAILURE": {
        return {
          ...state,
          createGenderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_GENDER": {
        return {
          ...state,
          createGenderSuccess: false,
          createGenderFailure: false,
          createGenderData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_GENDER_SUCCESS": {             
        return {
          ...state,
          updateGenderSuccess: true,
          updateGenderData: action.payload.data,
        };
      }
      case "UPDATE_GENDER_FAILURE": {
        return {
          ...state,
          updateGenderFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_GENDER": {
        return {
          ...state,
          updateGenderSuccess: false,
          updateGenderFailure: false,
          updateGenderData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default GenderReducer
  
  