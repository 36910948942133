import { lazy } from "react";

const ShiftList = lazy(() => import("./shiftList"));

const shiftRoutes = [
  {
    path: "/shift",
    element: <ShiftList />,
  }
];

export default shiftRoutes;
