const initialState = {
    enrollmeList: []  
  }
  
  const EnrollmentReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          getEnrollmentSuccess: true,
          getEnrollmentList: action.payload.data,
        };
      }
      case "GET_ENROLLMENT_FAILURE": {
        return {
          ...state,
          getEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ENROLLMENT": {
        return {
          ...state,
          getEnrollmentSuccess: false,
          getEnrollmentFailure: false,
          getEnrollmentList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          createEnrollmentSuccess: true,
          createEnrollmentData: action.payload.data,
        };
      }
      case "CREATE_ENROLLMENT_FAILURE": {
        return {
          ...state,
          createEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ENROLLMENT": {
        return {
          ...state,
          createEnrollmentSuccess: false,
          createEnrollmentFailure: false,
          createEnrollmentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          updateEnrollmentSuccess: true,
          updateEnrollmentData: action.payload.data,
        };
      }
      case "UPDATE_ENROLLMENT_FAILURE": {
        return {
          ...state,
          updateEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ENROLLMENT": {
        return {
          ...state,
          updateEnrollmentSuccess: false,
          updateEnrollmentFailure: false,
          updateEnrollmentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EnrollmentReducer
  
  