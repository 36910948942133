import { lazy } from "react";

const HomeWorkList = lazy(() => import("./homeWorkList"));

const homeWorkRoutes = [
  {
    path: "/homework",
    element: <HomeWorkList />,
  }
];

export default homeWorkRoutes;
