import { lazy } from "react";

const BrandList = lazy(() => import("./brand"));
const CategoryList = lazy(() => import("./category"));
const MaterialType = lazy(() => import("./material-type"));
const MaterialList = lazy(() => import("./materials"));
const SupplierList = lazy(() => import("./supplier"));
const PurchaseOrder = lazy(() => import("./purchase-order"));

const productRoutes = [
  {
    path: "/brand-list",
    element: <BrandList />,
  },
  {
    path: "/category-list",
    element: <CategoryList />,
  },
  {
    path: "/material-type",
    element: <MaterialType />,
  },
  {
    path: "/material-list",
    element: <MaterialList />,
  },
  {
    path: "/supplier-list",
    element: <SupplierList />,
  },
  {
    path: "/purchase-order",
    element: <PurchaseOrder />,
  }
];

export default productRoutes;
