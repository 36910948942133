import { lazy } from "react";

const AccessList = lazy(() => import("./accessList"));

const accessRoutes = [
  {
    path: "/access",
    element: <AccessList />,
  }
];

export default accessRoutes;
