const initialState = {
    BookList: []  
  }
  
  const BookReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_BOOKS_SUCCESS": {             
        return {
          ...state,
          getBookSuccess: true,
          getBookList: action.payload.data,
        };
      }
      case "GET_BOOKS_FAILURE": {
        return {
          ...state,
          getBookFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_BOOKS": {
        return {
          ...state,
          getBookSuccess: false,
          getBookFailure: false,
          getBookList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_BOOKS_SUCCESS": {             
        return {
          ...state,
          createBookSuccess: true,
          createBookData: action.payload.data,
        };
      }
      case "CREATE_BOOKS_FAILURE": {
        return {
          ...state,
          createBookFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_BOOKS": {
        return {
          ...state,
          createBookSuccess: false,
          createBookFailure: false,
          createBookData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_BOOKS_SUCCESS": {             
        return {
          ...state,
          updateBookSuccess: true,
          updateBookData: action.payload.data,
        };
      }
      case "UPDATE_BOOKS_FAILURE": {
        return {
          ...state,
          updateBookFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_BOOKS": {
        return {
          ...state,
          updateBookSuccess: false,
          updateBookFailure: false,
          updateBookData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default BookReducer
  
  