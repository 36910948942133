import { lazy } from "react";

const EmployeeList = lazy(() => import("./employeeList"));

const employeeRoutes = [
  {
    path: "/employee",
    element: <EmployeeList />,
  }
];

export default employeeRoutes;
