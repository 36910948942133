const initialState = {
    studentList: []  
  }
  
  const StudentReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_STUDENT_SUCCESS": {       
        return {
          ...state,
          getStudentSuccess: true,
          getStudentList: action.payload.data,
        };
      }
      case "GET_STUDENT_FAILURE": {
        return {
          ...state,
          getStudentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT": {
        return {
          ...state,
          getStudentSuccess: false,
          getStudentFailure: false,
          getStudentList: [],
          errorMessage: false
        };
      }

      case "GET_STUDENT_DETAILS_SUCCESS": {       
        return {
          ...state,
          getStudentDetailsSuccess: true,
          getStudentDetailsList: action.payload.data,
        };
      }
      case "GET_STUDENT_DETAILS_FAILURE": {
        return {
          ...state,
          getStudentDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_DETAILS": {
        return {
          ...state,
          getStudentDetailsSuccess: false,
          getStudentDetailsFailure: false,
          getStudentDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_STUDENT_SUCCESS": {   
        console.log(JSON.stringify(action))
          
        return {
          ...state,
          createStudentSuccess: true,
          createStudentData: action.payload.data,
        };
      }
      case "CREATE_STUDENT_FAILURE": {
        return {
          ...state,
          createStudentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_STUDENT": {
        return {
          ...state,
          createStudentSuccess: false,
          createStudentFailure: false,
          createStudentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_STUDENT_SUCCESS": {             
        return {
          ...state,
          updateStudentSuccess: true,
          updateStudentData: action.payload.data,
        };
      }
      case "UPDATE_STUDENT_FAILURE": {
        return {
          ...state,
          updateStudentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_STUDENT": {
        return {
          ...state,
          updateStudentSuccess: false,
          updateStudentFailure: false,
          updateStudentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default StudentReducer
  
  