const initialState = {
    studentEnrollmentList: []  
  }
  
  const StudentEnrollmentReducer = function (state = initialState, action) { 
    switch (action.type) {
      case "GET_STUDENT_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          getStudentEnrollmentSuccess: true,
          getStudentEnrollmentList: action.payload.data,
        };
      }
      case "GET_STUDENT_ENROLLMENT_FAILURE": {
        return {
          ...state,
          getStudentEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_ENROLLMENT": {
        return {
          ...state,
          getStudentEnrollmentSuccess: false,
          getStudentEnrollmentFailure: false,
          getStudentEnrollmentList: [],
          errorMessage: false
        };
      }

      case "GET_STUDENT_ENROLLMENT_DETAILS_SUCCESS": {             
        return {
          ...state,
          getStudentEnrollmentDetailsSuccess: true,
          getStudentEnrollmentDetailsList: action.payload.data,
        };
      }
      case "GET_STUDENT_ENROLLMENT_DETAILS_FAILURE": {
        return {
          ...state,
          getStudentEnrollmentDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_ENROLLMENT_DETAILS": {
        return {
          ...state,
          getStudentEnrollmentDetailsSuccess: false,
          getStudentEnrollmentDetailsFailure: false,
          getStudentEnrollmentDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_STUDENT_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          createStudentEnrollmentSuccess: true,
          createStudentEnrollmentData: action.payload.data,
        };
      }
      case "CREATE_STUDENT_ENROLLMENT_FAILURE": {
        return {
          ...state,
          createStudentEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_STUDENT_ENROLLMENT": {
        return {
          ...state,
          createStudentEnrollmentSuccess: false,
          createStudentEnrollmentFailure: false,
          createStudentEnrollmentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_STUDENT_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          updateStudentEnrollmentSuccess: true,
          updateStudentEnrollmentData: action.payload.data,
        };
      }
      case "UPDATE_STUDENT_ENROLLMENT_FAILURE": {
        return {
          ...state,
          updateStudentEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_STUDENT_ENROLLMENT": {
        return {
          ...state,
          updateStudentEnrollmentSuccess: false,
          updateStudentEnrollmentFailure: false,
          updateStudentEnrollmentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default StudentEnrollmentReducer
  
  