const initialState = {
    periodTimeList: []  
  }
  
  const PeriodTimeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_PERIOD_TIME_SUCCESS": {             
        return {
          ...state,
          getPeriodTimeSuccess: true,
          getPeriodTimeList: action.payload.data,
        };
      }
      case "GET_PERIOD_TIME_FAILURE": {
        return {
          ...state,
          getPeriodTimeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PERIOD_TIME": {
        return {
          ...state,
          getPeriodTimeSuccess: false,
          getPeriodTimeFailure: false,
          getPeriodTimeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PERIOD_TIME_SUCCESS": {             
        return {
          ...state,
          createPeriodTimeSuccess: true,
          createPeriodTimeData: action.payload.data,
        };
      }
      case "CREATE_PERIOD_TIME_FAILURE": {
        return {
          ...state,
          createPeriodTimeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PERIOD_TIME": {
        return {
          ...state,
          createPeriodTimeSuccess: false,
          createPeriodTimeFailure: false,
          createPeriodTimeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_PERIOD_TIME_SUCCESS": {             
        return {
          ...state,
          updatePeriodTimeSuccess: true,
          updatePeriodTimeData: action.payload.data,
        };
      }
      case "UPDATE_PERIOD_TIME_FAILURE": {
        return {
          ...state,
          updatePeriodTimeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_PERIOD_TIME": {
        return {
          ...state,
          updatePeriodTimeSuccess: false,
          updatePeriodTimeFailure: false,
          updatePeriodTimeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default PeriodTimeReducer
  
  