import { lazy } from "react";

const BatchList = lazy(() => import("./batchList"));

const batchRoutes = [
  {
    path: "/batch",
    element: <BatchList />,
  }
];

export default batchRoutes;
