const initialState = {
    circularList: []  
  }
  
  const CircularReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CIRCULAR_SUCCESS": {             
        return {
          ...state,
          getCircularSuccess: true,
          getCircularList: action.payload.data,
        };
      }
      case "GET_CIRCULAR_FAILURE": {
        return {
          ...state,
          getCircularFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CIRCULAR": {
        return {
          ...state,
          getCircularSuccess: false,
          getCircularFailure: false,
          getCircularList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CIRCULAR_SUCCESS": {             
        return {
          ...state,
          createCircularSuccess: true,
          createCircularData: action.payload.data,
        };
      }
      case "CREATE_CIRCULAR_FAILURE": {
        return {
          ...state,
          createCircularFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CIRCULAR": {
        return {
          ...state,
          createCircularSuccess: false,
          createCircularFailure: false,
          createCircularData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CIRCULAR_SUCCESS": {             
        return {
          ...state,
          updateCircularSuccess: true,
          updateCircularData: action.payload.data,
        };
      }
      case "UPDATE_CIRCULAR_FAILURE": {
        return {
          ...state,
          updateCircularFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CIRCULAR": {
        return {
          ...state,
          updateCircularSuccess: false,
          updateCircularFailure: false,
          updateCircularData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CircularReducer
  
  