const initialState = {
    timeTableList: []  
  }
  
  const TimeTableReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TIME_TABLE_SUCCESS": {             
        return {
          ...state,
          getTimeTableSuccess: true,
          getTimeTableList: action.payload.data,
        };
      }
      case "GET_TIME_TABLE_FAILURE": {
        return {
          ...state,
          getTimeTableFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TIME_TABLE": {
        return {
          ...state,
          getTimeTableSuccess: false,
          getTimeTableFailure: false,
          getTimeTableList: [],
          errorMessage: false
        };
      }

      case "GET_TIME_TABLE_INFO_SUCCESS": {             
        return {
          ...state,
          getTimeTableInfoSuccess: true,
          getTimeTableInfoList: action.payload.data,
        };
      }
      case "GET_TIME_TABLE_INFO_FAILURE": {
        return {
          ...state,
          getTimeTableInfoFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TIME_INFO_TABLE": {
        return {
          ...state,
          getTimeTableInfoSuccess: false,
          getTimeTableInfoFailure: false,
          getTimeTableInfoList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TIME_TABLE_SUCCESS": {             
        return {
          ...state,
          createTimeTableSuccess: true,
          createTimeTableData: action.payload.data,
        };
      }
      case "CREATE_TIME_TABLE_FAILURE": {
        return {
          ...state,
          createTimeTableFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TIME_TABLE": {
        return {
          ...state,
          createTimeTableSuccess: false,
          createTimeTableFailure: false,
          createTimeTableData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TIME_TABLE_SUCCESS": {             
        return {
          ...state,
          updateTimeTableSuccess: true,
          updateTimeTableData: action.payload.data,
        };
      }
      case "UPDATE_TIME_TABLE_FAILURE": {
        return {
          ...state,
          updateTimeTableFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TIME_TABLE": {
        return {
          ...state,
          updateTimeTableSuccess: false,
          updateTimeTableFailure: false,
          updateTimeTableData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TimeTableReducer
  
  