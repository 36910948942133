import { lazy } from "react";

const EmployeeLeaveList = lazy(() => import("./employeeLeaveList"));

const employeeLeaveRoutes = [
  {
    path: "/employee-leave",
    element: <EmployeeLeaveList />,
  }
];

export default employeeLeaveRoutes;
