import { lazy } from "react";

const GradeList = lazy(() => import("./gradeList"));

const gradeRoutes = [
  {
    path: "/grade",
    element: <GradeList />,
  }
];

export default gradeRoutes;
