import { lazy } from "react";

const EnquiryList = lazy(() => import("./enquiryList"));

const EnquiryRoutes = [
  {
    path: "/enquiry",
    element: <EnquiryList />,
  }
];

export default EnquiryRoutes;
