import { lazy } from "react";

const PeriodTimeList = lazy(() => import("./periodTimeList"));

const PeriodTimeRoutes = [
  {
    path: "/periodtime",
    element: <PeriodTimeList />,
  }
];

export default PeriodTimeRoutes;
