const initialState = {
    workingDaysList: []  
  }
  
  const WorkingDaysReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_WORKING_DAYS_SUCCESS": {             
        return {
          ...state,
          getWorkingDaysSuccess: true,
          getWorkingDaysList: action.payload.data,
        };
      }
      case "GET_WORKING_DAYS_FAILURE": {
        return {
          ...state,
          getWorkingDaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_WORKING_DAYS": {
        return {
          ...state,
          getWorkingDaysSuccess: false,
          getWorkingDaysFailure: false,
          getWorkingDaysList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_WORKING_DAYS_SUCCESS": {             
        return {
          ...state,
          createWorkingDaysSuccess: true,
          createWorkingDaysData: action.payload.data,
        };
      }
      case "CREATE_WORKING_DAYS_FAILURE": {
        return {
          ...state,
          createWorkingDaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_WORKING_DAYS": {
        return {
          ...state,
          createWorkingDaysSuccess: false,
          createWorkingDaysFailure: false,
          createWorkingDaysData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_WORKING_DAYS_SUCCESS": {             
        return {
          ...state,
          updateWorkingDaysSuccess: true,
          updateWorkingDaysData: action.payload.data,
        };
      }
      case "UPDATE_WORKING_DAYS_FAILURE": {
        return {
          ...state,
          updateWorkingDaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_WORKING_DAYS": {
        return {
          ...state,
          updateWorkingDaysSuccess: false,
          updateWorkingDaysFailure: false,
          updateWorkingDaysData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default WorkingDaysReducer
  
  