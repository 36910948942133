import { lazy } from "react";

const SalesList = lazy(() => import("./salesList"));

const SalesRoutes = [
  {
    path: "/saless-list",
    element: <SalesList />,
  }
];

export default SalesRoutes;
