import { lazy } from "react";

const EmployeStatusList = lazy(() => import("./employeeStatusList"));

const employeStatusRoutes = [
  {
    path: "/employee-status",
    element: <EmployeStatusList />,
  }
];

export default employeStatusRoutes;
