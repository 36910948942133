import { lazy } from "react";

const SubjectEnrollmentList = lazy(() => import("./subjectEnrollmentList"));

const subjectEnrollmentRoutes = [
  {
    path: "/subject-enrollment",
    element: <SubjectEnrollmentList />,
  }
];

export default subjectEnrollmentRoutes;
