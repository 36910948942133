const initialState = {
    sectionList: []  
  }
  
  const SectionReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SECTION_SUCCESS": {             
        return {
          ...state,
          getSectionSuccess: true,
          getSectionList: action.payload.data,
        };
      }
      case "GET_SECTION_FAILURE": {
        return {
          ...state,
          getSectionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SECTION": {
        return {
          ...state,
          getSectionSuccess: false,
          getSectionFailure: false,
          getSectionList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SECTION_SUCCESS": {             
        return {
          ...state,
          createSectionSuccess: true,
          createSectionData: action.payload.data,
        };
      }
      case "CREATE_SECTION_FAILURE": {
        return {
          ...state,
          createSectionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SECTION": {
        return {
          ...state,
          createSectionSuccess: false,
          createSectionFailure: false,
          createSectionData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SECTION_SUCCESS": {             
        return {
          ...state,
          updateSectionSuccess: true,
          updateSectionData: action.payload.data,
        };
      }
      case "UPDATE_SECTION_FAILURE": {
        return {
          ...state,
          updateSectionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SECTION": {
        return {
          ...state,
          updateSectionSuccess: false,
          updateSectionFailure: false,
          updateSectionData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SectionReducer
  
  