const initialState = {
    proofTypeList: []  
  }
  
  const ProofTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_PROOF_TYPE_SUCCESS": {             
        return {
          ...state,
          getProofTypeSuccess: true,
          getProofTypeList: action.payload.data,
        };
      }
      case "GET_PROOF_TYPE_FAILURE": {
        return {
          ...state,
          getProofTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PROOF_TYPE": {
        return {
          ...state,
          getProofTypeSuccess: false,
          getProofTypeFailure: false,
          getProofTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PROOF_TYPE_SUCCESS": {             
        return {
          ...state,
          createProofTypeSuccess: true,
          createProofTypeData: action.payload.data,
        };
      }
      case "CREATE_PROOF_TYPE_FAILURE": {
        return {
          ...state,
          createProofTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PROOF_TYPE": {
        return {
          ...state,
          createProofTypeSuccess: false,
          createProofTypeFailure: false,
          createProofTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_PROOF_TYPE_SUCCESS": {             
        return {
          ...state,
          updateProofTypeSuccess: true,
          updateProofTypeData: action.payload.data,
        };
      }
      case "UPDATE_PROOF_TYPE_FAILURE": {
        return {
          ...state,
          updateProofTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_PROOF_TYPE": {
        return {
          ...state,
          updateProofTypeSuccess: false,
          updateProofTypeFailure: false,
          updateProofTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ProofTypeReducer
  
  