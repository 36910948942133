import { lazy } from "react";

const FeesEnrollmentList = lazy(() => import("./feesEnrollmentList"));

const feesEnrollmentRoutes = [
  {
    path: "/fees-enrollment",
    element: <FeesEnrollmentList />,
  }
];

export default feesEnrollmentRoutes;
