const initialState = {
    casteList: []  
  }
  
  const CasteReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CASTE_SUCCESS": {             
        return {
          ...state,
          getCasteSuccess: true,
          getCasteList: action.payload.data,
        };
      }
      case "GET_CASTE_FAILURE": {
        return {
          ...state,
          getCasteFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CASTE": {
        return {
          ...state,
          getCasteSuccess: false,
          getCasteFailure: false,
          getCasteList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CASTE_SUCCESS": {             
        return {
          ...state,
          createCasteSuccess: true,
          createCasteData: action.payload.data,
        };
      }
      case "CREATE_CASTE_FAILURE": {
        return {
          ...state,
          createCasteFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CASTE": {
        return {
          ...state,
          createCasteSuccess: false,
          createCasteFailure: false,
          createCasteData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CASTE_SUCCESS": {             
        return {
          ...state,
          updateCasteSuccess: true,
          updateCasteData: action.payload.data,
        };
      }
      case "UPDATE_CASTE_FAILURE": {
        return {
          ...state,
          updateCasteFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CASTE": {
        return {
          ...state,
          updateCasteSuccess: false,
          updateCasteFailure: false,
          updateCasteData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CasteReducer
  
  