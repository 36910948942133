import { lazy } from "react";

const DepartmentList = lazy(() => import("./departmentList"));

const departmentRoutes = [
  {
    path: "/department",
    element: <DepartmentList />,
  }
];

export default departmentRoutes;
