import { lazy } from "react";

const LeaveTypeList = lazy(() => import("./leaveTypeList"));

const leaveTypeRoutes = [
  {
    path: "/leave-type",
    element: <LeaveTypeList />,
  }
];

export default leaveTypeRoutes;
