import { lazy } from "react";

const DesignationList = lazy(() => import("./designationList"));

const designationRoutes = [
  {
    path: "/designation",
    element: <DesignationList />,
  }
];

export default designationRoutes;
