import { lazy } from "react";

const CircularList = lazy(() => import("./circularList"));

const circularRoutes = [
  {
    path: "/circular",
    element: <CircularList />,
  }
];

export default circularRoutes;
