import { lazy } from "react";

const StudentAttendanceList = lazy(() => import("./studentAttendanceList"));

const StudentAttendanceRoutes = [
  {
    path: "/student-attendance",
    element: <StudentAttendanceList />,
  }
];

export default StudentAttendanceRoutes;
