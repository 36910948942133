import { lazy } from "react";

const EmployeeReportList = lazy(() => import("./employeeReportList"));

const employeeReportRoutes = [
  {
    path: "/employee-report",
    element: <EmployeeReportList />,
  }
];

export default employeeReportRoutes;
