import { lazy } from "react";

const WarehouseList = lazy(() => import("./warehouseList"));

const warehouseRoutes = [
  {
    path: "/warehouse",
    element: <WarehouseList />
  }
];

export default warehouseRoutes;
