const initialState = {
  examTypeList: []  
}

const ExamTypeReducer = function (state = initialState, action) {  
  switch (action.type) {
    case "GET_EXAM_TYPE_SUCCESS": {             
      return {
        ...state,
        getExamTypeSuccess: true,
        getExamTypeList: action.payload.data,
      };
    }
    case "GET_EXAM_TYPE_FAILURE": {
      return {
        ...state,
        getExamTypeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_EXAM_TYPE": {
      return {
        ...state,
        getExamTypeSuccess: false,
        getExamTypeFailure: false,
        getExamTypeList: [],
        errorMessage: false
      };
    }
    
    case "CREATE_EXAM_TYPE_SUCCESS": {             
      return {
        ...state,
        createExamTypeSuccess: true,
        createExamTypeData: action.payload.data,
      };
    }
    case "CREATE_EXAM_TYPE_FAILURE": {
      return {
        ...state,
        createExamTypeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_EXAM_TYPE": {
      return {
        ...state,
        createExamTypeSuccess: false,
        createExamTypeFailure: false,
        createExamTypeData: false,
        errorMessage: false         
      };
    }

    case "UPDATE_EXAM_TYPE_SUCCESS": {             
      return {
        ...state,
        updateExamTypeSuccess: true,
        updateExamTypeData: action.payload.data,
      };
    }
    case "UPDATE_EXAM_TYPE_FAILURE": {
      return {
        ...state,
        updateExamTypeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_EXAM_TYPE": {
      return {
        ...state,
        updateExamTypeSuccess: false,
        updateExamTypeFailure: false,
        updateExamTypeData: false,
        errorMessage: false         
      };
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default ExamTypeReducer

