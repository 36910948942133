import { lazy } from "react";

const QuotationList = lazy(() => import("./quotationList"));

const QuotationRoutes = [
  {
    path: "/quotations-list",
    element: <QuotationList />,
  }
];

export default QuotationRoutes;
