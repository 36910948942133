const initialState = {
    userList: []  
  }
  
  const UnitTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_UNIT_TYPE_SUCCESS": {             
        return {
          ...state,
          getUnitTypeSuccess: true,
          getUnitTypeList: action.payload.data,
        };
      }
      case "GET_UNIT_TYPE_FAILURE": {
        return {
          ...state,
          getUnitTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_UNIT_TYPE": {
        return {
          ...state,
          getUnitTypeSuccess: false,
          getUnitTypeFailure: false,
          getUnitTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_UNIT_TYPE_SUCCESS": {             
        return {
          ...state,
          createUnitTypeSuccess: true,
          createUnitTypeData: action.payload.data,
        };
      }
      case "CREATE_UNIT_TYPE_FAILURE": {
        return {
          ...state,
          createUnitTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_UNIT_TYPE": {
        return {
          ...state,
          createUnitTypeSuccess: false,
          createUnitTypeFailure: false,
          createUnitTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_UNIT_TYPE_SUCCESS": {             
        return {
          ...state,
          updateUnitTypeSuccess: true,
          updateUnitTypeData: action.payload.data,
        };
      }
      case "UPDATE_UNIT_TYPE_FAILURE": {
        return {
          ...state,
          updateUnitTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_UNIT_TYPE": {
        return {
          ...state,
          updateUnitTypeSuccess: false,
          updateUnitTypeFailure: false,
          updateUnitTypeData: false,
          errorMessage: false         
        };
      }
      
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default UnitTypeReducer
  
  