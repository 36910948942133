const initialState = {
    periodDaysList: []  
  }
  
  const PeriodDaysReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_PERIOD_DAYS_SUCCESS": {             
        return {
          ...state,
          getPeriodDaysSuccess: true,
          getPeriodDaysList: action.payload.data,
        };
      }
      case "GET_PERIOD_DAYS_FAILURE": {
        return {
          ...state,
          getPeriodDaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PERIOD_DAYS": {
        return {
          ...state,
          getPeriodDaysSuccess: false,
          getPeriodDaysFailure: false,
          getPeriodDaysList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PERIOD_DAYS_SUCCESS": {             
        return {
          ...state,
          createPeriodDaysSuccess: true,
          createPeriodDaysData: action.payload.data,
        };
      }
      case "CREATE_PERIOD_DAYS_FAILURE": {
        return {
          ...state,
          createPeriodDaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PERIOD_DAYS": {
        return {
          ...state,
          createPeriodDaysSuccess: false,
          createPeriodDaysFailure: false,
          createPeriodDaysData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_PERIOD_DAYS_SUCCESS": {             
        return {
          ...state,
          updatePeriodDaysSuccess: true,
          updatePeriodDaysData: action.payload.data,
        };
      }
      case "UPDATE_PERIOD_DAYS_FAILURE": {
        return {
          ...state,
          updatePeriodDaysFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_PERIOD_DAYS": {
        return {
          ...state,
          updatePeriodDaysSuccess: false,
          updatePeriodDaysFailure: false,
          updatePeriodDaysData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default PeriodDaysReducer
  
  