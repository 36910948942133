const initialState = {
    employeeLeaveList: []  
  }
  
  const EmployeeLeaveReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_EMPLOYEE_LEAVE_SUCCESS": {             
        return {
          ...state,
          getEmployeeLeaveSuccess: true,
          getEmployeeLeaveList: action.payload.data,
        };
      }
      case "GET_EMPLOYEE_LEAVE_FAILURE": {
        return {
          ...state,
          getEmployeeLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EMPLOYEE_LEAVE": {
        return {
          ...state,
          getEmployeeLeaveSuccess: false,
          getEmployeeLeaveFailure: false,
          getEmployeeLeaveList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_EMPLOYEE_LEAVE_SUCCESS": {             
        return {
          ...state,
          createEmployeeLeaveSuccess: true,
          createEmployeeLeaveData: action.payload.data,
        };
      }
      case "CREATE_EMPLOYEE_LEAVE_FAILURE": {
        return {
          ...state,
          createEmployeeLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_EMPLOYEE_LEAVE": {
        return {
          ...state,
          createEmployeeLeaveSuccess: false,
          createEmployeeLeaveFailure: false,
          createEmployeeLeaveData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_EMPLOYEE_LEAVE_SUCCESS": {             
        return {
          ...state,
          updateEmployeeLeaveSuccess: true,
          updateEmployeeLeaveData: action.payload.data,
        };
      }
      case "UPDATE_EMPLOYEE_LEAVE_FAILURE": {
        return {
          ...state,
          updateEmployeeLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_EMPLOYEE_LEAVE": {
        return {
          ...state,
          updateEmployeeLeaveSuccess: false,
          updateEmployeeLeaveFailure: false,
          updateEmployeeLeaveData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EmployeeLeaveReducer
  
  