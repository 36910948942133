const initialState = {
    showMaterialList: []  
  }
  
  const ShowMaterialReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SHOW_MATERIAL_SUCCESS": {             
        return {
          ...state,
          getShowMaterialSuccess: true,
          getShowMaterialList: action.payload.data,
        };
      }
      case "GET_SHOW_MATERIAL_FAILURE": {
        return {
          ...state,
          getShowMaterialFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SHOW_MATERIAL": {
        return {
          ...state,
          getShowMaterialSuccess: false,
          getShowMaterialFailure: false,
          getShowMaterialList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SHOW_MATERIAL_SUCCESS": {             
        return {
          ...state,
          createShowMaterialSuccess: true,
          createShowMaterialData: action.payload.data,
        };
      }
      case "CREATE_SHOW_MATERIAL_FAILURE": {
        return {
          ...state,
          createShowMaterialFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SHOW_MATERIAL": {
        return {
          ...state,
          createShowMaterialSuccess: false,
          createShowMaterialFailure: false,
          createShowMaterialData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SHOW_MATERIAL_SUCCESS": {             
        return {
          ...state,
          updateShowMaterialSuccess: true,
          updateShowMaterialData: action.payload.data,
        };
      }
      case "UPDATE_SHOW_MATERIAL_FAILURE": {
        return {
          ...state,
          updateShowMaterialFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SHOW_MATERIAL": {
        return {
          ...state,
          updateShowMaterialSuccess: false,
          updateShowMaterialFailure: false,
          updateShowMaterialData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ShowMaterialReducer
  
  