const initialState = {
    studentLeaveList: []  
  }
  
  const StudentLeaveReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_STUDENT_LEAVE_SUCCESS": {  
        // alert("hii")           
        return {
          ...state,
          getStudentLeaveSuccess: true,
          getStudentLeaveList: action.payload.data,
        };
      }
      case "GET_STUDENT_LEAVE_FAILURE": {
        return {
          ...state,
          getStudentLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_LEAVE": {
        return {
          ...state,
          getStudentLeaveSuccess: false,
          getStudentLeaveFailure: false,
          getStudentLeaveList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_STUDENT_LEAVE_SUCCESS": {             
        return {
          ...state,
          createStudentLeaveSuccess: true,
          createStudentLeaveData: action.payload.data,
        };
      }
      case "CREATE_STUDENT_LEAVE_FAILURE": {
        return {
          ...state,
          createStudentLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_STUDENT_LEAVE": {
        return {
          ...state,
          createStudentLeaveSuccess: false,
          createStudentLeaveFailure: false,
          createStudentLeaveData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_STUDENT_LEAVE_SUCCESS": {             
        return {
          ...state,
          updateStudentLeaveSuccess: true,
          updateStudentLeaveData: action.payload.data,
        };
      }
      case "UPDATE_STUDENT_LEAVE_FAILURE": {
        return {
          ...state,
          updateStudentLeaveFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_STUDENT_LEAVE": {
        return {
          ...state,
          updateStudentLeaveSuccess: false,
          updateStudentLeaveFailure: false,
          updateStudentLeaveData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default StudentLeaveReducer
  
  