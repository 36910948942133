const initialState = {
    markSheetList: []  
  }
  
  const MarkSheetReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_MARK_SHEET_SUCCESS": {       
        return {
          ...state,
          getMarkSheetSuccess: true,
          getMarkSheetList: action.payload.data,
        };
      }
      case "GET_MARK_SHEET_FAILURE": {
        return {
          ...state,
          getMarkSheetFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_MARK_SHEET": {
        return {
          ...state,
          getMarkSheetSuccess: false,
          getMarkSheetFailure: false,
          getMarkSheetList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_MARK_SHEET_SUCCESS": {             
        return {
          ...state,
          createMarkSheetSuccess: true,
          createMarkSheetData: action.payload.data,
        };
      }
      case "CREATE_MARK_SHEET_FAILURE": {
        return {
          ...state,
          createMarkSheetFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_MARK_SHEET": {
        return {
          ...state,
          createMarkSheetSuccess: false,
          createMarkSheetFailure: false,
          createMarkSheetData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_MARK_SHEET_SUCCESS": {             
        return {
          ...state,
          updateMarkSheetSuccess: true,
          updateMarkSheetData: action.payload.data,
        };
      }
      case "UPDATE_MARK_SHEET_FAILURE": {
        return {
          ...state,
          updateMarkSheetFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_MARK_SHEET": {
        return {
          ...state,
          updateMarkSheetSuccess: false,
          updateMarkSheetFailure: false,
          updateMarkSheetData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default MarkSheetReducer
  
  