const initialState = {
    qualificationList: []  
  }
  
  const QualificationReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_QUALIFICATION_SUCCESS": {             
        return {
          ...state,
          getQualificationSuccess: true,
          getQualificationList: action.payload.data,
        };
      }
      case "GET_QUALIFICATION_FAILURE": {
        return {
          ...state,
          getQualificationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_QUALIFICATION": {
        return {
          ...state,
          getQualificationSuccess: false,
          getQualificationFailure: false,
          getQualificationList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_QUALIFICATION_SUCCESS": {             
        return {
          ...state,
          createQualificationSuccess: true,
          createQualificationData: action.payload.data,
        };
      }
      case "CREATE_QUALIFICATION_FAILURE": {
        return {
          ...state,
          createQualificationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_QUALIFICATION": {
        return {
          ...state,
          createQualificationSuccess: false,
          createQualificationFailure: false,
          createQualificationData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_QUALIFICATION_SUCCESS": {             
        return {
          ...state,
          updateQualificationSuccess: true,
          updateQualificationData: action.payload.data,
        };
      }
      case "UPDATE_QUALIFICATION_FAILURE": {
        return {
          ...state,
          updateQualificationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_QUALIFICATION": {
        return {
          ...state,
          updateQualificationSuccess: false,
          updateQualificationFailure: false,
          updateQualificationData: false,
          errorMessage: false         
        };
      }

      case "DELETE_EMPLOYEE_QUALIFICATION_SUCCESS": {             
        return {
          ...state,
          deleteEmployeeQualificationSuccess: true,
        };
      }
      case "DELETE_EMPLOYEE_QUALIFICATION_FAILURE": {
        return {
          ...state,
          deleteEmployeeQualificationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_EMPLOYEE_QUALIFICATION": {
        return {
          ...state,
          deleteEmployeeQualificationSuccess: false,
          deleteEmployeeQualificationFailure: false,
          errorMessage: false         
        };
      }
      
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default QualificationReducer
  
  