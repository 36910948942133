const initialState = {
    receiptList: []  
  }
  
  const ReceiptListReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_RECEIPT_LIST_SUCCESS": {             
        return {
          ...state,
          getReceiptSuccess: true,
          getReceiptList: action.payload.data,
        };
      }
      case "GET_RECEIPT_LIST_FAILURE": {
        return {
          ...state,
          getReceiptFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_RECEIPT_LIST": {
        return {
          ...state,
          getReceiptSuccess: false,
          getReceiptFailure: false,
          getReceiptList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_RECEIPT_LIST_SUCCESS": {             
        return {
          ...state,
          createReceiptSuccess: true,
          createReceiptData: action.payload.data,
        };
      }
      case "CREATE_RECEIPT_LIST_FAILURE": {
        return {
          ...state,
          createReceiptFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_RECEIPT_LIST": {
        return {
          ...state,
          createReceiptSuccess: false,
          createReceiptFailure: false,
          createReceiptData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_RECEIPT_LIST_SUCCESS": {             
        return {
          ...state,
          updateReceiptSuccess: true,
          updateReceiptData: action.payload.data,
        };
      }
      case "UPDATE_RECEIPT_LIST_FAILURE": {
        return {
          ...state,
          updateReceiptFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_RECEIPT_LIST": {
        return {
          ...state,
          updateReceiptSuccess: false,
          updateReceiptFailure: false,
          updateReceiptData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ReceiptListReducer
  
  