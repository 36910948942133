const initialState = {
    feesTypeList: []  
  }
  
  const FeesTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_FEES_TYPE_SUCCESS": {             
        return {
          ...state,
          getFeesTypeSuccess: true,
          getFeesTypeList: action.payload.data,
        };
      }
      case "GET_FEES_TYPE_FAILURE": {
        return {
          ...state,
          getFeesTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_FEES_TYPE": {
        return {
          ...state,
          getFeesTypeSuccess: false,
          getFeesTypeFailure: false,
          getFeesTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_FEES_TYPE_SUCCESS": {             
        return {
          ...state,
          createFeesTypeSuccess: true,
          createFeesTypeData: action.payload.data,
        };
      }
      case "CREATE_FEES_TYPE_FAILURE": {
        return {
          ...state,
          createFeesTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_FEES_TYPE": {
        return {
          ...state,
          createFeesTypeSuccess: false,
          createFeesTypeFailure: false,
          createFeesTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_FEES_TYPE_SUCCESS": {             
        return {
          ...state,
          updateFeesTypeSuccess: true,
          updateFeesTypeData: action.payload.data,
        };
      }
      case "UPDATE_FEES_TYPE_FAILURE": {
        return {
          ...state,
          updateFeesTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_FEES_TYPE": {
        return {
          ...state,
          updateFeesTypeSuccess: false,
          updateFeesTypeFailure: false,
          updateFeesTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default FeesTypeReducer
  
  