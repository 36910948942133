const initialState = {
    homeWorkList: []  
  }
  
  const HomeWorkReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_HOME_WORK_SUCCESS": {             
        return {
          ...state,
          getHomeWorkSuccess: true,
          getHomeWorkList: action.payload.data,
        };
      }
      case "GET_HOME_WORK_FAILURE": {
        return {
          ...state,
          getHomeWorkFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_HOME_WORK": {
        return {
          ...state,
          getHomeWorkSuccess: false,
          getHomeWorkFailure: false,
          getHomeWorkList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_HOME_WORK_SUCCESS": {             
        return {
          ...state,
          createHomeWorkSuccess: true,
          createHomeWorkData: action.payload.data,
        };
      }
      case "CREATE_HOME_WORK_FAILURE": {
        return {
          ...state,
          createHomeWorkFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_HOME_WORK": {
        return {
          ...state,
          createHomeWorkSuccess: false,
          createHomeWorkFailure: false,
          createHomeWorkData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_HOME_WORK_SUCCESS": {             
        return {
          ...state,
          updateHomeWorkSuccess: true,
          updateHomeWorkData: action.payload.data,
        };
      }
      case "UPDATE_HOME_WORK_FAILURE": {
        return {
          ...state,
          updateHomeWorkFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_HOME_WORK": {
        return {
          ...state,
          updateHomeWorkSuccess: false,
          updateHomeWorkFailure: false,
          updateHomeWorkData: false,
          errorMessage: false         
        };
      }

      case "DELETE_HOME_WORK_DETAILS_SUCCESS": {             
        return {
          ...state,
          deleteHomeWorkDetailsSuccess: true,
        };
      }
      case "DELETE_HOME_WORK_DETAILS_FAILURE": {
        return {
          ...state,
          deleteHomeWorkDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_HOME_WORK_DETAILS": {
        return {
          ...state,
          deleteHomeWorkDetailsSuccess: false,
          deleteHomeWorkDetailsFailure: false,
          errorMessage: false         
        };
      }

      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default HomeWorkReducer
  
  