import { lazy } from "react";

const DailyExamMarkSheetList = lazy(() => import("./dailyExamMarkSheetList"));

const DailyExamMarkSheetRoutes = [
  {
    path: "/daily-exam-mark-sheet",
    element: <DailyExamMarkSheetList />,
  }
];

export default DailyExamMarkSheetRoutes;
