const initialState = {
    quizList: []  
  }
  
  const QuizReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_QUIZ_SUCCESS": {             
        return {
          ...state,
          getQuizSuccess: true,
          getQuizList: action.payload.data,
        };
      }
      case "GET_QUIZ_FAILURE": {
        return {
          ...state,
          getQuizFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_QUIZ": {
        return {
          ...state,
          getQuizSuccess: false,
          getQuizFailure: false,
          getQuizList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_QUIZ_SUCCESS": {             
        return {
          ...state,
          createQuizSuccess: true,
          createQuizData: action.payload.data,
        };
      }
      case "CREATE_QUIZ_FAILURE": {
        return {
          ...state,
          createQuizFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_QUIZ": {
        return {
          ...state,
          createQuizSuccess: false,
          createQuizFailure: false,
          createQuizData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_QUIZ_SUCCESS": {             
        return {
          ...state,
          updateQuizSuccess: true,
          updateQuizData: action.payload.data,
        };
      }
      case "UPDATE_QUIZ_FAILURE": {
        return {
          ...state,
          updateQuizFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_QUIZ": {
        return {
          ...state,
          updateQuizSuccess: false,
          updateQuizFailure: false,
          updateQuizData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default QuizReducer
  
  