const initialState = {
    employeeSalaryTypeList: []  
   }
   
   const EmployeeSalaryTypeReducer = function (state = initialState, action) {  
     switch (action.type) {
       case "GET_EMPLOYEE_SALARY_TYPE_SUCCESS": {             
         return {
           ...state,
           getEmployeeSalaryTypeSuccess: true,
           getEmployeeSalaryTypeList: action.payload.data,
         };
       }
       case "GET_EMPLOYEE_SALARY_TYPE_FAILURE": {
         return {
           ...state,
           getEmployeeSalaryTypeFailure: true,
           errorMessage: action.errorMessage
         };
       }
       case "RESET_GET_EMPLOYEE_SALARY_TYPE": {
         return {
           ...state,
           getEmployeeSalaryTypeSuccess: false,
           getEmployeeSalaryTypeFailure: false,
           getEmployeeSalaryTypeList: [],
           errorMessage: false
         };
       }
       
       case "CREATE_EMPLOYEE_SALARY_TYPE_SUCCESS": {             
         return {
           ...state,
           createEmployeeSalaryTypeSuccess: true,
           createEmployeeSalaryTypeData: action.payload.data,
         };
       }
       case "CREATE_EMPLOYEE_SALARY_TYPE_FAILURE": {
         return {
           ...state,
           createEmployeeSalaryTypeFailure: true,
           errorMessage: action.errorMessage
         };
       }
       case "RESET_CREATE_EMPLOYEE_SALARY_TYPE": {
         return {
           ...state,
           createEmployeeSalaryTypeSuccess: false,
           createEmployeeSalaryTypeFailure: false,
           createEmployeeSalaryTypeData: false,
           errorMessage: false         
         };
       }
   
       case "UPDATE_EMPLOYEE_SALARY_TYPE_SUCCESS": {             
         return {
           ...state,
           updateEmployeeSalaryTypeSuccess: true,
           updateEmployeeSalaryTypeData: action.payload.data,
         };
       }
       case "UPDATE_EMPLOYEE_SALARY_TYPE_FAILURE": {
         return {
           ...state,
           updateEmployeeSalaryTypeFailure: true,
           errorMessage: action.errorMessage
         };
       }
       case "RESET_UPDATE_EMPLOYEE_SALARY_TYPE": {
         return {
           ...state,
           updateEmployeeSalaryTypeSuccess: false,
           updateEmployeeSalaryTypeFailure: false,
           updateEmployeeSalaryTypeData: false,
           errorMessage: false         
         };
       }
       default: {
         return {
           ...state,
         }
       }
     }
   }
   
   export default EmployeeSalaryTypeReducer
   
   