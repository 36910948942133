const initialState = {
    PettyCashList: []  
  }
  
  const PettyCashReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_PETTY_CASH_SUCCESS": {             
        return {
          ...state,
          getPettyCashSuccess: true,
          getPettyCashList: action.payload.data,
        };
      }
      case "GET_PETTY_CASH_FAILURE": {
        return {
          ...state,
          getPettyCashFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PETTY_CASH": {
        return {
          ...state,
          getPettyCashSuccess: false,
          getPettyCashFailure: false,
          getPettyCashList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PETTY_CASH_SUCCESS": {             
        return {
          ...state,
          createPettyCashSuccess: true,
          createPettyCashData: action.payload.data,
        };
      }
      case "CREATE_PETTY_CASH_FAILURE": {
        return {
          ...state,
          createPettyCashFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PETTY_CASH": {
        return {
          ...state,
          createPettyCashSuccess: false,
          createPettyCashFailure: false,
          createPettyCashData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_PETTY_CASH_SUCCESS": {             
        return {
          ...state,
          updatePettyCashSuccess: true,
          updatePettyCashData: action.payload.data,
        };
      }
      case "UPDATE_PETTY_CASH_FAILURE": {
        return {
          ...state,
          updatePettyCashFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_PETTY_CASH": {
        return {
          ...state,
          updatePettyCashTypeSuccess: false,
          updatePettyCashTypeFailure: false,
          updatePettyCashTypeData: false,
          errorMessage: false         
        };
      }

      case "GET_PETTY_CASH_SUCCESS_TYPE": {             
        return {
          ...state,
          getPettyCashTypeSuccess: true,
          getPettyCashTypeList: action.payload.data,
        };
      }
      case "GET_PETTY_CASH_FAILURE_TYPE": {
        return {
          ...state,
          getPettyCashTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PETTY_CASH_TYPE": {
        return {
          ...state,
          getPettyCashSuccess: false,
          getPettyCashFailure: false,
          getPettyCashList: [],
          errorMessage: false
        };
      }
      case "GET_PETTY_CASH_DETAILS_SUCCESS": {             
        return {
          ...state,
          getPettyCashDetailsSuccess: true,
          getPettyCashDetailsList: action.payload.data,
        };
      }
      case "GET_PETTY_CASH_DETAILS_FAILURE": {
        return {
          ...state,
          getPettyCashDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PETTY_CASH_DETAILS": {
        return {
          ...state,
          getPettyCashDetailsSuccess: false,
          getPettyCashDetailsFailure: false,
          getPettyCashDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PETTY_CASH_DETAILS_SUCCESS": {             
        return {
          ...state,
          createPettyCashDetailsSuccess: true,
          createPettyCashDetailsList: action.payload.data,
        };
      }
      case "CREATE_PETTY_CASH_DETAILS_FAILURE": {
        return {
          ...state,
          createPettyCashDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PETTY_CASH_DETAILS": {
        return {
          ...state,
          createPettyCashDetailsSuccess: false,
          createPettyCashDetailsFailure: false,
          createPettyCashDetailsList: [],
          errorMessage: false
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default PettyCashReducer
  
  