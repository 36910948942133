const initialState = {
    motherTongueList: []  
  }
  
  const MotherTongueReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_MOTHER_TONGUE_SUCCESS": {             
        return {
          ...state,
          getMotherTongueSuccess: true,
          getMotherTongueList: action.payload.data,
        };
      }
      case "GET_MOTHER_TONGUE_FAILURE": {
        return {
          ...state,
          getMotherTongueFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_MOTHER_TONGUE": {
        return {
          ...state,
          getMotherTongueSuccess: false,
          getMotherTongueFailure: false,
          getMotherTongueList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_MOTHER_TONGUE_SUCCESS": {             
        return {
          ...state,
          createMotherTongueSuccess: true,
          createMotherTongueData: action.payload.data,
        };
      }
      case "CREATE_MOTHER_TONGUE_FAILURE": {
        return {
          ...state,
          createMotherTongueFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_MOTHER_TONGUE": {
        return {
          ...state,
          createMotherTongueSuccess: false,
          createMotherTongueFailure: false,
          createMotherTongueData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_MOTHER_TONGUE_SUCCESS": {             
        return {
          ...state,
          updateMotherTongueSuccess: true,
          updateMotherTongueData: action.payload.data,
        };
      }
      case "UPDATE_MOTHER_TONGUE_FAILURE": {
        return {
          ...state,
          updateMotherTongueFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_MOTHER_TONGUE": {
        return {
          ...state,
          updateMotherTongueSuccess: false,
          updateMotherTongueFailure: false,
          updateMotherTongueData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default MotherTongueReducer
  
  