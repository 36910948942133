const initialState = {
    feesEnrollmentList: []  
  }
  
  const FeesEnrollmentReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_FEES_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          getFeesEnrollmentSuccess: true,
          getFeesEnrollmentList: action.payload.data,
        };
      }
      case "GET_FEES_ENROLLMENT_FAILURE": {
        return {
          ...state,
          getFeesEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_FEES_ENROLLMENT": {
        return {
          ...state,
          getFeesEnrollmentSuccess: false,
          getFeesEnrollmentFailure: false,
          getFeesEnrollmentList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_FEES_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          createFeesEnrollmentSuccess: true,
          createFeesEnrollmentData: action.payload.data,
        };
      }
      case "CREATE_FEES_ENROLLMENT_FAILURE": {
        return {
          ...state,
          createFeesEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_FEES_ENROLLMENT": {
        return {
          ...state,
          createFeesEnrollmentSuccess: false,
          createFeesEnrollmentFailure: false,
          createFeesEnrollmentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_FEES_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          updateFeesEnrollmentSuccess: true,
          updateFeesEnrollmentData: action.payload.data,
        };
      }
      case "UPDATE_FEES_ENROLLMENT_FAILURE": {
        return {
          ...state,
          updateFeesEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_FEES_ENROLLMENT": {
        return {
          ...state,
          updateFeesEnrollmentSuccess: false,
          updateFeesEnrollmentFailure: false,
          updateFeesEnrollmentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default FeesEnrollmentReducer
  
  