import { lazy } from "react";

const ProofTypeList = lazy(() => import("./proofTypeList"));

const proofTypeRoutes = [
  {
    path: "/proof-type",
    element: <ProofTypeList />,
  }
];

export default proofTypeRoutes;
