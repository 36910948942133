const initialState = {
    subjectEnrollmentList: []  
  }
  
  const SubjectEnrollmentReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SUBJECT_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          getSubjectEnrollmentSuccess: true,
          getSubjectEnrollmentList: action.payload.data,
        };
      }
      case "GET_SUBJECT_ENROLLMENT_FAILURE": {
        return {
          ...state,
          getSubjectEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SUBJECT_ENROLLMENT": {
        return {
          ...state,
          getSubjectEnrollmentSuccess: false,
          getSubjectEnrollmentFailure: false,
          getSubjectEnrollmentList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SUBJECT_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          createSubjectEnrollmentSuccess: true,
          createSubjectEnrollmentData: action.payload.data,
        };
      }
      case "CREATE_SUBJECT_ENROLLMENT_FAILURE": {
        return {
          ...state,
          createSubjectEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SUBJECT_ENROLLMENT": {
        return {
          ...state,
          createSubjectEnrollmentSuccess: false,
          createSubjectEnrollmentFailure: false,
          createSubjectEnrollmentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SUBJECT_ENROLLMENT_SUCCESS": {             
        return {
          ...state,
          updateSubjectEnrollmentSuccess: true,
          updateSubjectEnrollmentData: action.payload.data,
        };
      }
      case "UPDATE_SUBJECT_ENROLLMENT_FAILURE": {
        return {
          ...state,
          updateSubjectEnrollmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SUBJECT_ENROLLMENT": {
        return {
          ...state,
          updateSubjectEnrollmentSuccess: false,
          updateSubjectEnrollmentFailure: false,
          updateSubjectEnrollmentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SubjectEnrollmentReducer
  
  