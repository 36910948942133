const initialState = {
    classList: []  
  }
  
  const ClassReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CLASS_SUCCESS": {             
        return {
          ...state,
          getClassSuccess: true,
          getClassList: action.payload.data,
        };
      }
      case "GET_CLASS_FAILURE": {
        return {
          ...state,
          getClassFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CLASS": {
        return {
          ...state,
          getClassSuccess: false,
          getClassFailure: false,
          getClassList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CLASS_SUCCESS": {             
        return {
          ...state,
          createClassSuccess: true,
          createClassData: action.payload.data,
        };
      }
      case "CREATE_CLASS_FAILURE": {
        return {
          ...state,
          createClassFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CLASS": {
        return {
          ...state,
          createClassSuccess: false,
          createClassFailure: false,
          createClassData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CLASS_SUCCESS": {             
        return {
          ...state,
          updateClassSuccess: true,
          updateClassData: action.payload.data,
        };
      }
      case "UPDATE_CLASS_FAILURE": {
        return {
          ...state,
          updateClassFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CLASS": {
        return {
          ...state,
          updateClassSuccess: false,
          updateClassFailure: false,
          updateClassData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ClassReducer
  
  