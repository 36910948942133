import { lazy } from "react";

const InventoryAdjustmentList = lazy(() => import("./inventoryAdjustmentList"));

const inventoryAdjustmentRoutes = [
  {
    path: "/inventoryAdjustment",
    element: <InventoryAdjustmentList />
  }
];

export default inventoryAdjustmentRoutes;
