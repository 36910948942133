import { lazy } from "react";

const InventoryTransferList = lazy(() => import("./inventoryTransferList"));

const InventoryTransferRoutes = [
  {
    path: "/inventory-transfer",
    element: <InventoryTransferList/>,
  }
];

export default InventoryTransferRoutes;
