const initialState = {
    invoiceList: []  
  }
  
  const InventoryMaterialReducer = function (state = initialState, action) {  
    switch (action.type) {

      //inventory Transfer
      case "GET_INVENTORY_TRANSFER_SUCCESS": {             
        return {
          ...state,
          getInventoryTransferSuccess: true,
          getInventoryTransferList: action.payload.data,
        };
      }
      case "GET_INVENTORY_TRANSFER_FAILURE": {
        return {
          ...state,
          getInventoryTransferFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_INVENTORY_TRANSFER": {
        return {
          ...state,
          getInventoryTransferSuccess: false,
          getInventoryTransferFailure: false,
          getInventoryTransferList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_INVENTORY_TRANSFER_SUCCESS": {             
        return {
          ...state,
          createInventoryTransferSuccess: true,
          createInventoryTransferData: action.payload.data,
        };
      }
      case "CREATE_INVENTORY_TRANSFER_FAILURE": {
        return {
          ...state,
          createInventoryTransferFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_INVENTORY_TRANSFER": {
        return {
          ...state,
          createInventoryTransferSuccess: false,
          createInventoryTransferFailure: false,
          createInventoryTransferData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_INVENTORY_TRANSFER_SUCCESS": {             
        return {
          ...state,
          updateInventoryTransferSuccess: true,
          updateInventoryTransferData: action.payload.data,
        };
      }
      case "UPDATE_INVENTORY_TRANSFER_FAILURE": {
        return {
          ...state,
          updateInventoryTransferFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_INVENTORY_TRANSFER": {
        return {
          ...state,
          updateInventoryTransferSuccess: false,
          updateInventoryTransferFailure: false,
          updateInventoryTransferData: false,
          errorMessage: false         
        };
      }

      //Inventory
      case "GET_INVENTORY_MATERIAL_SUCCESS": {             
        return {
          ...state,
          getInventoryMaterialSuccess: true,
          getInventoryMaterialList: action.payload.data,
        };
      }
      case "GET_INVENTORY_MATERIAL_FAILURE": {
        return {
          ...state,
          getInventoryMaterialFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_INVENTORY_MATERIAL": {
        return {
          ...state,
          getInventoryMaterialSuccess: false,
          getInventoryMaterialFailure: false,
          getInventoryMaterialList: [],
          errorMessage: false
        };
      }


      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default InventoryMaterialReducer
  
  