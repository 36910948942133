import { lazy } from "react";

const DeliveryList = lazy(() => import("./deliveryList"));

const DeliveryRoutes = [
  {
    path: "/deliverys-list",
    element: <DeliveryList />,
  }
];

export default DeliveryRoutes;
