const initialState = {
    schoolInfoList: []  
  }
  
  const SchoolInfoReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SCHOOL_INFO_SUCCESS": {             
        return {
          ...state,
          getSchoolInfoSuccess: true,
          getSchoolInfoList: action.payload.data,
        };
      }
      case "GET_SCHOOL_INFO_FAILURE": {
        return {
          ...state,
          getSchoolInfoFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SCHOOL_INFO": {
        return {
          ...state,
          getSchoolInfoSuccess: false,
          getSchoolInfoFailure: false,
          getSchoolInfoList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SCHOOL_INFO_SUCCESS": {             
        return {
          ...state,
          createSchoolInfoSuccess: true,
          createSchoolInfoData: action.payload.data,
        };
      }
      case "CREATE_SCHOOL_INFO_FAILURE": {
        return {
          ...state,
          createSchoolInfoFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SCHOOL_INFO": {
        return {
          ...state,
          createSchoolInfoSuccess: false,
          createSchoolInfoFailure: false,
          createSchoolInfoData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SCHOOL_INFO_SUCCESS": {             
        return {
          ...state,
          updateSchoolInfoSuccess: true,
          updateSchoolInfoData: action.payload.data,
        };
      }
      case "UPDATE_SCHOOL_INFO_FAILURE": {
        return {
          ...state,
          updateSchoolInfoFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SCHOOL_INFO": {
        return {
          ...state,
          updateSchoolInfoSuccess: false,
          updateSchoolInfoFailure: false,
          updateSchoolInfoData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SchoolInfoReducer
  
  