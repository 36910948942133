const initialState = {
    syllabusList: []  
  }
  
  const SyllabusReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SYLLABUS_SUCCESS": {             
        return {
          ...state,
          getSyllabusSuccess: true,
          getSyllabusList: action.payload.data,
        };
      }
      case "GET_SYLLABUS_FAILURE": {
        return {
          ...state,
          getSyllabusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SYLLABUS": {
        return {
          ...state,
          getSyllabusSuccess: false,
          getSyllabusFailure: false,
          getSyllabusList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SYLLABUS_SUCCESS": {             
        return {
          ...state,
          createSyllabusSuccess: true,
          createSyllabusData: action.payload.data,
        };
      }
      case "CREATE_SYLLABUS_FAILURE": {
        return {
          ...state,
          createSyllabusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SYLLABUS": {
        return {
          ...state,
          createSyllabusSuccess: false,
          createSyllabusFailure: false,
          createSyllabusData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SYLLABUS_SUCCESS": {             
        return {
          ...state,
          updateSyllabusSuccess: true,
          updateSyllabusData: action.payload.data,
        };
      }
      case "UPDATE_SYLLABUS_FAILURE": {
        return {
          ...state,
          updateSyllabusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SYLLABUS": {
        return {
          ...state,
          updateSyllabusSuccess: false,
          updateSyllabusFailure: false,
          updateSyllabusData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SyllabusReducer
  
  