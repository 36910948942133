const initialState = {
    salesList: []  
  }
  
  const SalesReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SALES_SUCCESS": {             
        return {
          ...state,
          getSalesSuccess: true,
          getSalesList: action.payload.data,
        };
      }
      case "GET_SALES_FAILURE": {
        return {
          ...state,
          getSalesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SALES": {
        return {
          ...state,
          getSalesSuccess: false,
          getSalesFailure: false,
          getSalesList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SALES_SUCCESS": {             
        return {
          ...state,
          createSalesSuccess: true,
          createSalesData: action.payload.data,
        };
      }
      case "CREATE_SALES_FAILURE": {
        return {
          ...state,
          createSalesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SALES": {
        return {
          ...state,
          createSalesSuccess: false,
          createSalesFailure: false,
          createSalesData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SALES_SUCCESS": {             
        return {
          ...state,
          updateSalesSuccess: true,
          updateSalesData: action.payload.data,
        };
      }
      case "UPDATE_SALES_FAILURE": {
        return {
          ...state,
          updateSalesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SALES": {
        return {
          ...state,
          updateSalesSuccess: false,
          updateSalesFailure: false,
          updateSalesData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SalesReducer
  
  