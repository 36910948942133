const initialState = {
    examVariantList: []  
  }
  
  const ExamVariantReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_EXAM_VARIANT_SUCCESS": {             
        return {
          ...state,
          getExamVariantSuccess: true,
          getExamVariantList: action.payload.data,
        };
      }
      case "GET_EXAM_VARIANT_FAILURE": {
        return {
          ...state,
          getExamVariantFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EXAM_VARIANT": {
        return {
          ...state,
          getExamVariantSuccess: false,
          getExamVariantFailure: false,
          getExamVariantList: [],
          errorMessage: false
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ExamVariantReducer
  
  