const initialState = {
    studentFeesPaymentList: []  
  }
  
  const StudentFeesPaymentReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_STUDENT_FEES_PAYMENT_SUCCESS": {             
        return {
          ...state,
          getStudentFeesPaymentSuccess: true,
          getStudentFeesPaymentList: action.payload.data,
        };
      }
      case "GET_STUDENT_FEES_PAYMENT_FAILURE": {
        return {
          ...state,
          getStudentFeesPaymentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_FEES_PAYMENT": {
        return {
          ...state,
          getStudentFeesPaymentSuccess: false,
          getStudentFeesPaymentFailure: false,
          getStudentFeesPaymentList: [],
          errorMessage: false
        };
      }

      case "GET_STUDENT_FEES_PAYMENT_DETAILS_SUCCESS": {             
        return {
          ...state,
          getStudentFeesPaymentDetailsSuccess: true,
          getStudentFeesPaymentDetailsList: action.payload.data,
        };
      }
      case "GET_STUDENT_FEES_PAYMENT_DETAILS_FAILURE": {
        return {
          ...state,
          getStudentFeesPaymentDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_FEES_DETAILS_PAYMENT": {
        return {
          ...state,
          getStudentFeesPaymentDetailsSuccess: false,
          getStudentFeesPaymentDetailsFailure: false,
          getStudentFeesPaymentDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_STUDENT_FEES_PAYMENT_SUCCESS": {             
        return {
          ...state,
          createStudentFeesPaymentSuccess: true,
          createStudentFeesPaymentData: action.payload.data,
        };
      }
      case "CREATE_STUDENT_FEES_PAYMENT_FAILURE": {
        return {
          ...state,
          createStudentFeesPaymentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_STUDENT_FEES_PAYMENT": {
        return {
          ...state,
          createStudentFeesPaymentSuccess: false,
          createStudentFeesPaymentFailure: false,
          createStudentFeesPaymentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_STUDENT_FEES_PAYMENT_SUCCESS": {             
        return {
          ...state,
          updateStudentFeesPaymentSuccess: true,
          updateStudentFeesPaymentData: action.payload.data,
        };
      }
      case "UPDATE_STUDENT_FEES_PAYMENT_FAILURE": {
        return {
          ...state,
          updateStudentFeesPaymentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_STUDENT_FEES_PAYMENT": {
        return {
          ...state,
          updateStudentFeesPaymentSuccess: false,
          updateStudentFeesPaymentFailure: false,
          updateStudentFeesPaymentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default StudentFeesPaymentReducer
  
  