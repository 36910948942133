const initialState = {
    leaveTypeList: []  
  }
  
  const LeaveTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_LEAVE_TYPE_SUCCESS": {             
        return {
          ...state,
          getLeaveTypeSuccess: true,
          getLeaveTypeList: action.payload.data,
        };
      }
      case "GET_LEAVE_TYPE_FAILURE": {
        return {
          ...state,
          getLeaveTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LEAVE_TYPE": {
        return {
          ...state,
          getLeaveTypeSuccess: false,
          getLeaveTypeFailure: false,
          getLeaveTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LEAVE_TYPE_SUCCESS": {             
        return {
          ...state,
          createLeaveTypeSuccess: true,
          createLeaveTypeData: action.payload.data,
        };
      }
      case "CREATE_LEAVE_TYPE_FAILURE": {
        return {
          ...state,
          createLeaveTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LEAVE_TYPE": {
        return {
          ...state,
          createLeaveTypeSuccess: false,
          createLeaveTypeFailure: false,
          createLeaveTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LEAVE_TYPE_SUCCESS": {             
        return {
          ...state,
          updateLeaveTypeSuccess: true,
          updateLeaveTypeData: action.payload.data,
        };
      }
      case "UPDATE_LEAVE_TYPE_FAILURE": {
        return {
          ...state,
          updateLeaveTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LEAVE_TYPE": {
        return {
          ...state,
          updateLeaveTypeSuccess: false,
          updateLeaveTypeFailure: false,
          updateLeaveTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default LeaveTypeReducer
  
  