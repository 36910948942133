import { lazy } from "react";

const EmployeePromotionList = lazy(() => import("./employeePromotionList"));

const employeePromotionRoutes = [
  {
    path: "/employee-promotion",
    element: <EmployeePromotionList />,
  }
];

export default employeePromotionRoutes;
