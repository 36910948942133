import { lazy } from "react";

const InventoryList = lazy(() => import("./inventoryList"));

const InventoryRoutes = [
  {
    path: "/inventory",
    element: <InventoryList/>,
  }
];

export default InventoryRoutes;
