const initialState = {
    assignTypeList: []  
  }
  
  const AssignTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ASSIGN_TYPE_SUCCESS": {             
        return {
          ...state,
          getAssignTypeSuccess: true,
          getAssignTypeList: action.payload.data,
        };
      }
      case "GET_ASSIGN_TYPE_FAILURE": {
        return {
          ...state,
          getAssignTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ASSIGN_TYPE": {
        return {
          ...state,
          getAssignTypeSuccess: false,
          getAssignTypeFailure: false,
          getAssignTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ASSIGN_TYPE_SUCCESS": {             
        return {
          ...state,
          createAssignTypeSuccess: true,
          createAssignTypeData: action.payload.data,
        };
      }
      case "CREATE_ASSIGN_TYPE_FAILURE": {
        return {
          ...state,
          createAssignTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ASSIGN_TYPE": {
        return {
          ...state,
          createAssignTypeSuccess: false,
          createAssignTypeFailure: false,
          createAssignTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ASSIGN_TYPE_SUCCESS": {             
        return {
          ...state,
          updateAssignTypeSuccess: true,
          updateAssignTypeData: action.payload.data,
        };
      }
      case "UPDATE_ASSIGN_TYPE_FAILURE": {
        return {
          ...state,
          updateAssignTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ASSIGN_TYPE": {
        return {
          ...state,
          updateAssignTypeSuccess: false,
          updateAssignTypeFailure: false,
          updateAssignTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default AssignTypeReducer
  
  