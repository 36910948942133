import { lazy } from "react";

const PagePermissionList = lazy(() => import("./pagePermissionList"));

const PagePermissionRoutes = [
  {
    path: "/page-permission",
    element: <PagePermissionList />,
  }
];

export default PagePermissionRoutes;
