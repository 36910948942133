import { lazy } from "react";

const KnowledgeBookList = lazy(() => import("./knowledgeBook"));

const KnowledgeBookListRoutes = [
  {
    path: "/knowledge-book",
    element: <KnowledgeBookList />,
  }
];

export default KnowledgeBookListRoutes;
