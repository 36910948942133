import { lazy } from "react";

const StudentLeaveList = lazy(() => import("./studentLeaveList"));

const studentLeaveRoutes = [
  {
    path: "/student-leave",
    element: <StudentLeaveList />,
  }
];

export default studentLeaveRoutes;
