import { lazy } from "react";

const SubjectList = lazy(() => import("./subjectList"));

const SubjectRoutes = [
  {
    path: "/subject",
    element: <SubjectList />,
  }
];

export default SubjectRoutes;
