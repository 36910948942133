const initialState = {
    eventList: []
  }
  
  const EventReducer = function (state = initialState, action) {
    switch (action.type) {
      case "GET_EVENT_SUCCESS": {
        return {
          ...state,
          getEventSuccess: true,
          getEventList: action.payload.data,
        };
      }
      case "GET_EVENT_FAILURE": {
        return {
          ...state,
          getEventFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EVENT": {
        return {
          ...state,
          getEventSuccess: false,
          getEventFailure: false,
          getEventList: [],
          errorMessage: false
        };
      }
  
      case "GET_EVENT_LIST_SUCCESS": {
        return {
          ...state,
          getEventListSuccess: true,
          getEventListList: action.payload.data,
        };
      }
      case "GET_EVENT_LIST_FAILURE": {
        return {
          ...state,
          getEventListFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EVENT_LIST": {
        return {
          ...state,
          getEventListSuccess: false,
          getEventListFailure: false,
          getEventListList: [],
          errorMessage: false
        };
      }
  
      case "CREATE_EVENT_SUCCESS": {
        return {
          ...state,
          createEventSuccess: true,
          createEventData: action.payload.data,
        };
      }
      case "CREATE_EVENT_FAILURE": {
        return {
          ...state,
          createEventFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_EVENT": {
        return {
          ...state,
          createEventSuccess: false,
          createEventFailure: false,
          createEventData: false,
          errorMessage: false
        };
      }
  
      case "UPDATE_EVENT_SUCCESS": {
        return {
          ...state,
          updateEventSuccess: true,
          updateEventData: action.payload.data,
        };
      }
      case "UPDATE_EVENT_FAILURE": {
        return {
          ...state,
          updateEventFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_EVENT": {
        return {
          ...state,
          updateEventSuccess: false,
          updateEventFailure: false,
          updateEventData: false,
          errorMessage: false
        };
      }
  
      case "DELETE_EVENT_DETAILS_SUCCESS": {
        return {
          ...state,
          deleteEventDetailsSuccess: true,
        };
      }
      case "DELETE_EVENT_DETAILS_FAILURE": {
        return {
          ...state,
          deleteEventDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_EVENT_DETAILS": {
        return {
          ...state,
          deleteEventDetailsSuccess: false,
          deleteEventDetailsFailure: false,
          errorMessage: false
        };
      }
  
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EventReducer
  
  