const initialState = {
    employeeProofList: []  
  }
  
  const EmployeeProofReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_EMPLOYEE_PROOF_SUCCESS": {             
        return {
          ...state,
          getEmployeeProofSuccess: true,
          getEmployeeProofList: action.payload.data,
        };
      }
      case "GET_EMPLOYEE_PROOF_FAILURE": {
        return {
          ...state,
          getEmployeeProofFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EMPLOYEE_PROOF": {
        return {
          ...state,
          getEmployeeProofSuccess: false,
          getEmployeeProofFailure: false,
          getEmployeeProofList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_EMPLOYEE_PROOF_SUCCESS": {             
        return {
          ...state,
          createEmployeeProofSuccess: true,
          createEmployeeProofData: action.payload.data,
        };
      }
      case "CREATE_EMPLOYEE_PROOF_FAILURE": {
        return {
          ...state,
          createEmployeeProofFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_EMPLOYEE_PROOF": {
        return {
          ...state,
          createEmployeeProofSuccess: false,
          createEmployeeProofFailure: false,
          createEmployeeProofData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_EMPLOYEE_PROOF_SUCCESS": {             
        return {
          ...state,
          updateEmployeeProofSuccess: true,
          updateEmployeeProofData: action.payload.data,
        };
      }
      case "UPDATE_EMPLOYEE_PROOF_FAILURE": {
        return {
          ...state,
          updateEmployeeProofFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_EMPLOYEE_PROOF": {
        return {
          ...state,
          updateEmployeeProofSuccess: false,
          updateEmployeeProofFailure: false,
          updateEmployeeProofData: false,
          errorMessage: false         
        };
      }

      case "DELETE_EMPLOYEE_PROOF_SUCCESS": {             
        return {
          ...state,
          deleteEmployeeProofSuccess: true,
        };
      }
      case "DELETE_EMPLOYEE_PROOF_FAILURE": {
        return {
          ...state,
          deleteEmployeeProofFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_EMPLOYEE_PROOF": {
        return {
          ...state,
          deleteEmployeeProofSuccess: false,
          deleteEmployeeProofFailure: false,
          errorMessage: false         
        };
      }
      
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EmployeeProofReducer
  
  