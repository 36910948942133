import { lazy } from "react";

const StudentListList = lazy(() => import("./studentListList"));

const studentListRoutes = [
  {
    path: "/student-list",
    element: <StudentListList />,
  }
];

export default studentListRoutes;
