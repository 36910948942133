const initialState = {
    classList: []  
  }
  
  const CountryReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_COUNTRY_SUCCESS": {             
        return {
          ...state,
          getCountrySuccess: true,
          getCountryList: action.payload.data,
        };
      }
      case "GET_COUNTRY_FAILURE": {
        return {
          ...state,
          getCountryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_COUNTRY": {
        return {
          ...state,
          getCountrySuccess: false,
          getCountryFailure: false,
          getCountryList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_COUNTRY_SUCCESS": {             
        return {
          ...state,
          createCountrySuccess: true,
          createCountryData: action.payload.data,
        };
      }
      case "CREATE_COUNTRY_FAILURE": {
        return {
          ...state,
          createCountryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_COUNTRY": {
        return {
          ...state,
          createCountrySuccess: false,
          createCountryFailure: false,
          createCountryData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_COUNTRY_SUCCESS": {             
        return {
          ...state,
          updateCountrySuccess: true,
          updateCountryData: action.payload.data,
        };
      }
      case "UPDATE_COUNTRY_FAILURE": {
        return {
          ...state,
          updateCountryFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_COUNTRY": {
        return {
          ...state,
          updateCOUNTRYSuccess: false,
          updateCOUNTRYFailure: false,
          updateCOUNTRYData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CountryReducer
  
  