import { lazy } from "react";

const ReceiptList = lazy(() => import("./receiptList"));

const ReceiptRoutes = [
  {
    path: "/receipts-list",
    element: <ReceiptList />,
  }
];

export default ReceiptRoutes;
