import { lazy } from "react";

const FeesTypeList = lazy(() => import("./feesTypeList"));

const feesTypeRoutes = [
  {
    path: "/fees-type",
    element: <FeesTypeList />,
  }
];

export default feesTypeRoutes;
