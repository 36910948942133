const initialState = {
    dashboardAttendanceList: []  
  }
  
  const DashboardAttendanceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DASHBOARD_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          getDashboardAttendanceSuccess: true,
          getDashboardAttendanceList: action.payload.data,
        };
      }
      case "GET_DASHBOARD_ATTENDANCE_FAILURE": {
        return {
          ...state,
          getDashboardAttendanceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DASHBOARD_ATTENDANCE": {
        return {
          ...state,
          getDashboardAttendanceSuccess: false,
          getDashboardAttendanceFailure: false,
          getDashboardAttendanceList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_DASHBOARD_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          createDashboardSuccess: true,
          createDashboardData: action.payload.data,
        };
      }
      case "CREATE_DASHBOARD_ATTENDANCE_FAILURE": {
        return {
          ...state,
          createDashboardFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DASHBOARD_ATTENDANCE": {
        return {
          ...state,
          createDashboardSuccess: false,
          createDashboardFailure: false,
          createDashboardData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DASHBOARD_ATTENDANCE_SUCCESS": {             
        return {
          ...state,
          updateDashboardSuccess: true,
          updateDashboardData: action.payload.data,
        };
      }
      case "UPDATE_DASHBOARD_ATTENDANCE_FAILURE": {
        return {
          ...state,
          updateDashboardFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DASHBOARD_ATTENDANCE": {
        return {
          ...state,
          updateDashboardSuccess: false,
          updateDashboardFailure: false,
          updateDashboardData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DashboardAttendanceReducer
  
  