import { lazy } from "react";

const StudentFeesPaymentList = lazy(() => import("./studentFeesPaymentList"));

const studentFeesPaymentRoutes = [
  {
    path: "/student-fees-payment",
    element: <StudentFeesPaymentList />,
  }
];

export default studentFeesPaymentRoutes;
