import { lazy } from "react";

const CreateQuizList = lazy(() => import("./createQuizList"));

const createQuizRoutes = [
  {
    path: "/create-quiz",
    element: <CreateQuizList />,
  }
];

export default createQuizRoutes;
