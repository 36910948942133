const initialState = {
    DailyExamList: []  
  }
  
  const DailyExamReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DAILY_EXAM_SUCCESS": {             
        return {
          ...state,
          getDailyExamSuccess: true,
          getDailyExamList: action.payload.data,
        };
      }
      case "GET_DAILY_EXAM_FAILURE": {
        return {
          ...state,
          getDailyExamFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DAILY_EXAM": {
        return {
          ...state,
          getDailyExamSuccess: false,
          getDailyExamFailure: false,
          getDailyExamList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_DAILY_EXAM_SUCCESS": {             
        return {
          ...state,
          createDailyExamSuccess: true,
          createDailyExamData: action.payload.data,
        };
      }
      case "CREATE_DAILY_EXAM_FAILURE": {
        return {
          ...state,
          createDailyExamFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DAILY_EXAM": {
        return {
          ...state,
          createDailyExamSuccess: false,
          createDailyExamFailure: false,
          createDailyExamData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DAILY_EXAM_SUCCESS": {             
        return {
          ...state,
          updateDailyExamSuccess: true,
          updateDailyExamData: action.payload.data,
        };
      }
      case "UPDATE_DAILY_EXAM_FAILURE": {
        return {
          ...state,
          updateDailyExamFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DAILY_EXAM": {
        return {
          ...state,
          updateDailyExamSuccess: false,
          updateDailyExamFailure: false,
          updateDailyExamData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DailyExamReducer
  
  