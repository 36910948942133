const initialState = {
    batchList: []  
  }
  
  const BatchReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_BATCH_SUCCESS": {             
        return {
          ...state,
          getBatchSuccess: true,
          getBatchList: action.payload.data,
        };
      }
      case "GET_BATCH_FAILURE": {
        return {
          ...state,
          getBatchFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_BATCH": {
        return {
          ...state,
          getBatchSuccess: false,
          getBatchFailure: false,
          getBatchList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_BATCH_SUCCESS": {             
        return {
          ...state,
          createBatchSuccess: true,
          createBatchData: action.payload.data,
        };
      }
      case "CREATE_BATCH_FAILURE": {
        return {
          ...state,
          createBatchFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_BATCH": {
        return {
          ...state,
          createBatchSuccess: false,
          createBatchFailure: false,
          createBatchData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_BATCH_SUCCESS": {             
        return {
          ...state,
          updateBatchSuccess: true,
          updateBatchData: action.payload.data,
        };
      }
      case "UPDATE_BATCH_FAILURE": {
        return {
          ...state,
          updateBatchFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_BATCH": {
        return {
          ...state,
          updateBatchSuccess: false,
          updateBatchFailure: false,
          updateBatchData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default BatchReducer
  
  