const initialState = {
    enquiryList: []
  }
  
  const EnquiryTypeReducer = function (state = initialState, action) {
    switch (action.type) {
      case "GET_ENQUIRY_TYPE_SUCCESS": {
        return {
          ...state,
          getEnquiryTypeSuccess: true,
          getEnquiryTypeList: action.payload.data,
        };
      }
      case "GET_ENQUIRY_TYPE_FAILURE": {
        return {
          ...state,
          getEnquiryTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ENQUIRY_TYPE": {
        return {
          ...state,
          getEnquiryTypeSuccess: false,
          getEnquiryTypeFailure: false,
          getEnquiryTypeList: [],
          errorMessage: false
        };
      }
  
      case "GET_ENQUIRY_TYPE_LIST_SUCCESS": {
        return {
          ...state,
          getEnquiryTypeListSuccess: true,
          getEnquiryTypeListList: action.payload.data,
        };
      }
      case "GET_ENQUIRY_TYPE_LIST_FAILURE": {
        return {
          ...state,
          getEnquiryTypeListFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ENQUIRY_TYPE_LIST": {
        return {
          ...state,
          getEnquiryTypeListSuccess: false,
          getEnquiryTypeListFailure: false,
          getEnquiryTypeListList: [],
          errorMessage: false
        };
      }
  
      case "CREATE_ENQUIRY_SUCCESS": {
        return {
          ...state,
          createEnquiryTypeSuccess: true,
          createEnquiryTypeData: action.payload.data,
        };
      }
      case "CREATE_ENQUIRY_FAILURE": {
        return {
          ...state,
          createEnquiryTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ENQUIRY": {
        return {
          ...state,
          createEnquiryTypeSuccess: false,
          createEnquiryTypeFailure: false,
          createEnquiryTypeData: false,
          errorMessage: false
        };
      }
  
      case "UPDATE_ENQUIRY_SUCCESS": {
        return {
          ...state,
          updateEnquiryTypeSuccess: true,
          updateEnquiryTypeData: action.payload.data,
        };
      }
      case "UPDATE_ENQUIRY_FAILURE": {
        return {
          ...state,
          updateEnquiryTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ENQUIRY": {
        return {
          ...state,
          updateEnquiryTypeSuccess: false,
          updateEnquiryTypeFailure: false,
          updateEnquiryTypeData: false,
          errorMessage: false
        };
      }
  
      case "DELETE_ENQUIRY_DETAILS_SUCCESS": {
        return {
          ...state,
          deleteEnquiryTypeDetailsSuccess: true,
        };
      }
      case "DELETE_ENQUIRY_DETAILS_FAILURE": {
        return {
          ...state,
          deleteEnquiryTypeDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_ENQUIRY_DETAILS": {
        return {
          ...state,
          deleteEnquiryTypeDetailsSuccess: false,
          deleteEnquiryTypeDetailsFailure: false,
          errorMessage: false
        };
      }
  
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EnquiryTypeReducer
  
  