
const initialState = {
    employeeDetailsList: []  
  }
  
  const EmployeeDetailsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_EMPLOYEE_DETAILS_SUCCESS": {               
        return {
          ...state,
          getEmployeeDetailsSuccess: true,
          getEmployeeDetailsList: action.payload.data,
        };
      }
      case "GET_EMPLOYEE_DETAILS_FAILURE": {
        return {
          ...state,
          getEmployeeDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EMPLOYEE_DETAILS": {
        return {
          ...state,
          getEmployeeDetailsSuccess: false,
          getEmployeeDetailsFailure: false,
          getEmployeeDetailsList: [],
          errorMessage: false
        };
      }
      case "CREATE_EMPLOYEE_DETAILS_SUCCESS": {             
        return {
          ...state,
          createEmployeeDetailsSuccess: true,
          createEmployeeDetailsData: action.payload.data,
        };
      }
      case "CREATE_EMPLOYEE_DETAILS_FAILURE": {
        return {
          ...state,
          createEmployeeDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_EMPLOYEE_DETAILS": {
        return {
          ...state,
          createEmployeeDetailsSuccess: false,
          createEmployeeDetailsFailure: false,
          createEmployeeDetailsData: [],
          errorMessage: false
        };
      }
      case "UPDATE_EMPLOYEE_DETAILS_SUCCESS": {             
        return {
          ...state,
          updateEmployeeDetailsSuccess: true,
          updateEmployeeDetailsData: action.payload.data,
        };
      }
      case "UPDATE_EMPLOYEE_DETAILS_FAILURE": {
        return {
          ...state,
          updateEmployeeDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_EMPLOYEE_DETAILS": {
        return {
          ...state,
          updateEmployeeDetailsSuccess: false,
          updateEmployeeDetailsFailure: false,
          updateEmployeeDetailsData: [],
          errorMessage: false
        };
      } 
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EmployeeDetailsReducer
  
  