import { lazy } from "react";

const BookList = lazy(() => import("./bookList"));

const BookRoutes = [
  {
    path: "/book",
    element: <BookList />,
  }
];

export default BookRoutes;
