const initialState = {
    subCasteList: []  
  }
  
  const SubCasteReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SUB_CASTE_SUCCESS": {             
        return {
          ...state,
          getSubCasteSuccess: true,
          getSubCasteList: action.payload.data,
        };
      }
      case "GET_SUB_CASTE_FAILURE": {
        return {
          ...state,
          getSubCasteFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SUB_CASTE": {
        return {
          ...state,
          getSubCasteSuccess: false,
          getSubCasteFailure: false,
          getSubCasteList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SUB_CASTE_SUCCESS": {             
        return {
          ...state,
          createSubCasteSuccess: true,
          createSubCasteData: action.payload.data,
        };
      }
      case "CREATE_SUB_CASTE_FAILURE": {
        return {
          ...state,
          createSubCasteFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SUB_CASTE": {
        return {
          ...state,
          createSubCasteSuccess: false,
          createSubCasteFailure: false,
          createSubCasteData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SUB_CASTE_SUCCESS": {             
        return {
          ...state,
          updateSubCasteSuccess: true,
          updateSubCasteData: action.payload.data,
        };
      }
      case "UPDATE_SUB_CASTE_FAILURE": {
        return {
          ...state,
          updateSubCasteFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SUB_CASTE": {
        return {
          ...state,
          updateSubCasteSuccess: false,
          updateSubCasteFailure: false,
          updateSubCasteData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SubCasteReducer
  
  