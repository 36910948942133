import { lazy } from "react";

const EnrollmentList = lazy(() => import("./enrollmentList"));

const enrollmentRoutes = [
  {
    path: "/enrollment",
    element: <EnrollmentList />,
  }
];

export default enrollmentRoutes;
