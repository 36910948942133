const initialState = {
    KnowledgeMaterialTypeList: []  
  }
  
  const KnowledgeMaterialTypeReducer = function (state = initialState, action) {  
    switch (action.type) {

      case "GET_KNOWLEDGE_MATERIAL_TYPE_SUCCESS": {             
        return {
          ...state,
          getKnowledgeMaterialTypeSuccess: true,
          getKnowledgeMaterialTypeList: action.payload.data,
        };
      }
      case "GET_KNOWLEDGE_MATERIAL_TYPE_FAILURE": {
        return {
          ...state,
          getKnowledgeMaterialTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_KNOWLEDGE_TYPE_MATERIAL": {
        return {
          ...state,
          getKnowledgeMaterialTypeSuccess: false,
          getKnowledgeMaterialTypeFailure: false,
          getKnowledgeMaterialTypeList: [],
          errorMessage: false
        };
      }
 
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default KnowledgeMaterialTypeReducer
  
  