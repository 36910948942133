import { lazy } from "react";

const GoodsReceivedList = lazy(() => import("./goodsReceivedList"));

const goodsReceivedRoutes = [
  {
    path: "/goods-received-list",
    element: <GoodsReceivedList />
  }
];

export default goodsReceivedRoutes;
