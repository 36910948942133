const initialState = {
    leaveStatusList: []  
  }
  
  const LeaveStatusReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_LEAVE_STATUS_SUCCESS": {             
        return {
          ...state,
          getLeaveStatusSuccess: true,
          getLeaveStatusList: action.payload.data,
        };
      }
      case "GET_LEAVE_STATUS_FAILURE": {
        return {
          ...state,
          getLeaveStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LEAVE_STATUS": {
        return {
          ...state,
          getLeaveStatusSuccess: false,
          getLeaveStatusFailure: false,
          getLeaveStatusList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LEAVE_STATUS_SUCCESS": {             
        return {
          ...state,
          createLeaveStatusSuccess: true,
          createLeaveStatusData: action.payload.data,
        };
      }
      case "CREATE_LEAVE_STATUS_FAILURE": {
        return {
          ...state,
          createLeaveStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LEAVE_STATUS": {
        return {
          ...state,
          createLeaveStatusSuccess: false,
          createLeaveStatusFailure: false,
          createLeaveStatusData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LEAVE_STATUS_SUCCESS": {             
        return {
          ...state,
          updateLeaveStatusSuccess: true,
          updateLeaveStatusData: action.payload.data,
        };
      }
      case "UPDATE_LEAVE_STATUS_FAILURE": {
        return {
          ...state,
          updateLeaveStatusFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LEAVE_STATUS": {
        return {
          ...state,
          updateLeaveStatusSuccess: false,
          updateLeaveStatusFailure: false,
          updateLeaveStatusData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default LeaveStatusReducer
  
  