const initialState = {
    accessList: []  
  }
  
  const AccessReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ACCESS_SUCCESS": {             
        return {
          ...state,
          getAccessSuccess: true,
          getAccessList: action.payload.data,
        };
      }
      case "GET_ACCESS_FAILURE": {
        return {
          ...state,
          getAccessFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ACCESS": {
        return {
          ...state,
          getAccessSuccess: false,
          getAccessFailure: false,
          getAccessList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ACCESS_SUCCESS": {             
        return {
          ...state,
          createAccessSuccess: true,
          createAccessData: action.payload.data,
        };
      }
      case "CREATE_ACCESS_FAILURE": {
        return {
          ...state,
          createAccessFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ACCESS": {
        return {
          ...state,
          createAccessSuccess: false,
          createAccessFailure: false,
          createAccessData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ACCESS_SUCCESS": {             
        return {
          ...state,
          updateAccessSuccess: true,
          updateAccessData: action.payload.data,
        };
      }
      case "UPDATE_ACCESS_FAILURE": {
        return {
          ...state,
          updateAccessFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ACCESS": {
        return {
          ...state,
          updateAccessSuccess: false,
          updateAccessFailure: false,
          updateAccessData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default AccessReducer
  
  