import { lazy } from "react";

const PettyCashList = lazy(() => import("./pettyCashList"));

const PettyCashRoutes = [
  {
    path: "/petty-cash",
    element: <PettyCashList />,
  }
];

export default PettyCashRoutes;
