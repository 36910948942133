const initialState = {
    BookTypeList: []  
  }
  
  const BookTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_BOOK_TYPE_SUCCESS": {             
        return {
          ...state,
          getBookTypeSuccess: true,
          getBookTypeList: action.payload.data,
        };
      }
      case "GET_BOOK_TYPE_FAILURE": {
        return {
          ...state,
          getBookTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_BOOK_TYPE": {
        return {
          ...state,
          getBookTypeSuccess: false,
          getBookTypeFailure: false,
          getBookTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_BOOK_TYPE_SUCCESS": {             
        return {
          ...state,
          createBookTypeSuccess: true,
          createBookTypeData: action.payload.data,
        };
      }
      case "CREATE_BOOK_TYPE_FAILURE": {
        return {
          ...state,
          createBookTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_BOOK_TYPE": {
        return {
          ...state,
          createBookTypeSuccess: false,
          createBookTypeFailure: false,
          createBookTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_BOOK_TYPE_SUCCESS": {             
        return {
          ...state,
          updateBookTypeSuccess: true,
          updateBookTypeData: action.payload.data,
        };
      }
      case "UPDATE_BOOK_TYPE_FAILURE": {
        return {
          ...state,
          updateBookTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_BOOK_TYPE": {
        return {
          ...state,
          updateBookTypeSuccess: false,
          updateBookTypeFailure: false,
          updateBookTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default BookTypeReducer
  
  