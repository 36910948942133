import { lazy } from "react";

const EmployeeAttendanceReportList = lazy(() => import("./employeeAttendanceReportList"));

const EmployeeAttendanceReportRoutes = [
  {
    path: "/employee-attendance-report",
    element: <EmployeeAttendanceReportList />,
  }
];

export default EmployeeAttendanceReportRoutes;
