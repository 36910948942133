import { lazy } from "react";

const QuizList = lazy(() => import("./quizList"));

const QuizRoutes = [
  {
    path: "/quiz",
    element: <QuizList />,
  }
];

export default QuizRoutes;
