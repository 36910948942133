import { lazy } from "react";

const SchoolInfoList = lazy(() => import("./schoolInfoList"));

const schoolInfoRoutes = [
  {
    path: "/school-info",
    element: <SchoolInfoList />,
  }
];

export default schoolInfoRoutes;
