import { lazy } from "react";

const CourseList = lazy(() => import("./courseList"));

const courseRoutes = [
  {
    path: "/course",
    element: <CourseList />,
  }
];

export default courseRoutes;
