const initialState = {
    employeeDocumentList: []  
  }
  
  const EmployeeDocumentReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_EMPLOYEE_DOCUMENT_SUCCESS": {             
        return {
          ...state,
          getEmployeeDocumentSuccess: true,
          getEmployeeDocumentList: action.payload.data,
        };
      }
      case "GET_EMPLOYEE_DOCUMENT_FAILURE": {
        return {
          ...state,
          getEmployeeDocumentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_EMPLOYEE_DOCUMENT": {
        return {
          ...state,
          getEmployeeDocumentSuccess: false,
          getEmployeeDocumentFailure: false,
          getEmployeeDocumentList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_EMPLOYEE_DOCUMENT_SUCCESS": {             
        return {
          ...state,
          createEmployeeDocumentSuccess: true,
          createEmployeeDocumentData: action.payload.data,
        };
      }
      case "CREATE_EMPLOYEE_DOCUMENT_FAILURE": {
        return {
          ...state,
          createEmployeeDocumentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_EMPLOYEE_DOCUMENT": {
        return {
          ...state,
          createEmployeeDocumentSuccess: false,
          createEmployeeDocumentFailure: false,
          createEmployeeDocumentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_EMPLOYEE_DOCUMENT_SUCCESS": {             
        return {
          ...state,
          updateEmployeeDocumentSuccess: true,
          updateEmployeeDocumentData: action.payload.data,
        };
      }
      case "UPDATE_EMPLOYEE_DOCUMENT_FAILURE": {
        return {
          ...state,
          updateEmployeeDocumentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_EMPLOYEE_DOCUMENT": {
        return {
          ...state,
          updateEmployeeDocumentSuccess: false,
          updateEmployeeDocumentFailure: false,
          updateEmployeeDocumentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EmployeeDocumentReducer
  
  