const initialState = {
  materialCodeList: []  
}

const MaterialCodeReducer = function (state = initialState, action) {  
  switch (action.type) {
    case "GET_MATERIALCODE_SUCCESS": {            
      return {
        ...state,
        getMaterialCodeSuccess: true,
        getMaterialCodeList: action.payload.data,
      };
    }
    case "GET_MATERIALCODE_FAILURE": {
      return {
        ...state,
        getMaterialCodeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_MATERIALCODE": {
      return {
        ...state,
        getMaterialCodeSuccess: false,
        getMaterialCodeFailure: false,
        getMaterialCodeList: [],
        errorMessage: false
      };
    }
   
    case "CREATE_MATERIALCODE_SUCCESS": {            
      return {
        ...state,
        createMaterialCodeSuccess: true,
        createMaterialCodeData: action.payload.data,
      };
    }
    case "CREATE_MATERIALCODE_FAILURE": {
      return {
        ...state,
        createMaterialCodeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_MATERIALCODE": {
      return {
        ...state,
        createMaterialCodeSuccess: false,
        createMaterialCodeFailure: false,
        createMaterialCodeData: false,
        errorMessage: false        
      };
    }

    case "UPDATE_MATERIALCODE_SUCCESS": {            
      return {
        ...state,
        updateMaterialCodeSuccess: true,
        updateMaterialCodeData: action.payload.data,
      };
    }
    case "UPDATE_MATERIALCODE_FAILURE": {
      return {
        ...state,
        updateMaterialCodeFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_MATERIALCODE": {
      return {
        ...state,
        updateMaterialCodeSuccess: false,
        updateMaterialCodeFailure: false,
        updateMaterialCodeData: false,
        errorMessage: false        
      };
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default MaterialCodeReducer

