const initialState = {
    dashboardList: []  
  }
  
  const DashboardReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DASHBOARD_SUCCESS": {             
        return {
          ...state,
          getDashboardSuccess: true,
          getDashboardList: action.payload.data,
        };
      }
      case "GET_DASHBOARD_FAILURE": {
        return {
          ...state,
          getDashboardFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DASHBOARD": {
        return {
          ...state,
          getDashboardSuccess: false,
          getDashboardFailure: false,
          getDashboardList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_DASHBOARD_SUCCESS": {             
        return {
          ...state,
          createDashboardSuccess: true,
          createDashboardData: action.payload.data,
        };
      }
      case "CREATE_DASHBOARD_FAILURE": {
        return {
          ...state,
          createDashboardFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DASHBOARD": {
        return {
          ...state,
          createDashboardSuccess: false,
          createDashboardFailure: false,
          createDashboardData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DASHBOARD_SUCCESS": {             
        return {
          ...state,
          updateDashboardSuccess: true,
          updateDashboardData: action.payload.data,
        };
      }
      case "UPDATE_DASHBOARD_FAILURE": {
        return {
          ...state,
          updateDashboardFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DASHBOARD": {
        return {
          ...state,
          updateDashboardSuccess: false,
          updateDashboardFailure: false,
          updateDashboardData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DashboardReducer
  
  