const initialState = {
    studentListList: []  
  }
  
  const StudentListReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_STUDENT_LIST_SUCCESS": {       
        return {
          ...state,
          getStudentListSuccess: true,
          getStudentListList: action.payload.data,
        };
      }
      case "GET_STUDENT_LIST_FAILURE": {
        return {
          ...state,
          getStudentListFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_LIST": {
        return {
          ...state,
          getStudentListSuccess: false,
          getStudentListFailure: false,
          getStudentListList: [],
          errorMessage: false
        };
      }

      case "GET_STUDENT_CLASS_LIST_SUCCESS": {             
        return {
          ...state,
          getStudentClassListSuccess: true,
          getStudentClassListList: action.payload.data,
        };
      }
      case "GET_STUDENT_CLASS_LIST_FAILURE": {
        return {
          ...state,
          getStudentClassListFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_STUDENT_CLASS_LIST": {
        return {
          ...state,
          getStudentClassListSuccess: false,
          getStudentClassListFailure: false,
          getStudentClassListList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_STUDENT_LIST_SUCCESS": {             
        return {
          ...state,
          createStudentListSuccess: true,
          createStudentListData: action.payload.data,
        };
      }
      case "CREATE_STUDENT_LIST_FAILURE": {
        return {
          ...state,
          createStudentListFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_STUDENT_LIST": {
        return {
          ...state,
          createStudentListSuccess: false,
          createStudentListFailure: false,
          createStudentListData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_STUDENT_LIST_SUCCESS": {             
        return {
          ...state,
          updateStudentListSuccess: true,
          updateStudentListData: action.payload.data,
        };
      }
      case "UPDATE_STUDENT_LIST_FAILURE": {
        return {
          ...state,
          updateStudentListFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_STUDENT_LIST": {
        return {
          ...state,
          updateStudentListSuccess: false,
          updateStudentListFailure: false,
          updateStudentListData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default StudentListReducer
