const initialState = {
    materialTypeList: []  
  }
  
  const MaterialTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_MATERIALTYPE_SUCCESS": {             
        return {
          ...state,
          getMaterialTypeSuccess: true,
          getMaterialTypeList: action.payload.data,
        };
      }
      case "GET_MATERIALTYPE_FAILURE": {
        return {
          ...state,
          getMaterialTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_MATERIALTYPE": {
        return {
          ...state,
          getMaterialTypeSuccess: false,
          getMaterialTypeFailure: false,
          getMaterialTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_MATERIALTYPE_SUCCESS": {             
        return {
          ...state,
          createMaterialTypeSuccess: true,
          createMaterialTypeData: action.payload.data,
        };
      }
      case "CREATE_MATERIALTYPE_FAILURE": {
        return {
          ...state,
          createMaterialTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_MATERIALTYPE": {
        return {
          ...state,
          createMaterialTypeSuccess: false,
          createMaterialTypeFailure: false,
          createMaterialTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_MATERIALTYPE_SUCCESS": {             
        return {
          ...state,
          updateMaterialTypeSuccess: true,
          updateMaterialTypeData: action.payload.data,
        };
      }
      case "UPDATE_MATERIALTYPE_FAILURE": {
        return {
          ...state,
          updateMaterialTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_MATERIALTYPE": {
        return {
          ...state,
          updateMaterialTypeSuccess: false,
          updateMaterialTypeFailure: false,
          updateMaterialTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default MaterialTypeReducer
  
  