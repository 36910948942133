import { lazy } from "react";

const BookType = lazy(() => import("./bookType"));

const BookTypeRoutes = [
  {
    path: "/book-type",
    element: <BookType />,
  }
];

export default BookTypeRoutes;
