const initialState = {
  examList: []
}

const ExamReducer = function (state = initialState, action) {
  switch (action.type) {
    case "GET_EXAM_SUCCESS": {
      return {
        ...state,
        getExamSuccess: true,
        getExamList: action.payload.data,
      };
    }
    case "GET_EXAM_FAILURE": {
      return {
        ...state,
        getExamFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_EXAM": {
      return {
        ...state,
        getExamSuccess: false,
        getExamFailure: false,
        getExamList: [],
        errorMessage: false
      };
    }
    case "GET_EXAM_STATUS_SUCCESS": {
      return {
        ...state,
        getExamStatusSuccess: true,
        getExamStatusList: action.payload.data,
      };
    }
    case "GET_EXAM_STATUS_FAILURE": {
      return {
        ...state,
        getExamStatusFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_EXAM_STATUS": {
      return {
        ...state,
        getExamStatusSuccess: false,
        getExamStatusFailure: false,
        getExamStatusList: [],
        errorMessage: false
      };
    }

    case "GET_EXAM_LIST_SUCCESS": {
      return {
        ...state,
        getExamListSuccess: true,
        getExamListList: action.payload.data,
      };
    }
    case "GET_EXAM_LIST_FAILURE": {
      return {
        ...state,
        getExamListFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_EXAM_LIST": {
      return {
        ...state,
        getExamListSuccess: false,
        getExamListFailure: false,
        getExamListList: [],
        errorMessage: false
      };
    }

    case "CREATE_EXAM_SUCCESS": {
      return {
        ...state,
        createExamSuccess: true,
        createExamData: action.payload.data,
      };
    }
    case "CREATE_EXAM_FAILURE": {
      return {
        ...state,
        createExamFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_EXAM": {
      return {
        ...state,
        createExamSuccess: false,
        createExamFailure: false,
        createExamData: false,
        errorMessage: false
      };
    }

    case "UPDATE_EXAM_SUCCESS": {
      return {
        ...state,
        updateExamSuccess: true,
        updateExamData: action.payload.data,
      };
    }
    case "UPDATE_EXAM_FAILURE": {
      return {
        ...state,
        updateExamFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_EXAM": {
      return {
        ...state,
        updateExamSuccess: false,
        updateExamFailure: false,
        updateExamData: false,
        errorMessage: false
      };
    }

    case "DELETE_EXAM_DETAILS_SUCCESS": {
      return {
        ...state,
        deleteExamDetailsSuccess: true,
      };
    }
    case "DELETE_EXAM_DETAILS_FAILURE": {
      return {
        ...state,
        deleteExamDetailsFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_DELETE_EXAM_DETAILS": {
      return {
        ...state,
        deleteExamDetailsSuccess: false,
        deleteExamDetailsFailure: false,
        errorMessage: false
      };
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

export default ExamReducer

