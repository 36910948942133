import { lazy } from "react";

const LeaveSettingsList = lazy(() => import("./leaveSettingsList"));

const leaveSettingsRoutes = [
  {
    path: "/leave-settings",
    element: <LeaveSettingsList />,
  }
];

export default leaveSettingsRoutes;
